import React from 'react'
import { Typography } from '@material-tailwind/react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules';

// Import Swiper styles
import "swiper/css";
import 'swiper/css/autoplay';


import Member from './Member/Member'
import Member1 from '../../../Assets/People/Team/vijay-barapatre.png'
import Member5 from '../../../Assets/People/Team/AD1.png'
import Member6 from '../../../Assets/People/Team/PB.png'


const memberArray = [
    {
        img: Member1,
        name: "Vijay Barapatre",
        designation: "CEO and MD",
    },
    {
        img: Member5,
        name: "Atharva Dhakulkar",
        designation: "Head Management",
    },
    {
        img: Member6,
        name: "Praveen Bhagavatula",
        designation: "COO",
    },
]

const Team = () => {
    return (
        <section className='bg-[#e9a8b259]'>
            <div className='max-w-[1600px] mx-auto py-10'>

                <div className='w-11/12 mx-auto lg:w-10/12'>
                    <div className='mb-10'>
                        <Typography variant='h3' className="font-['Cormorant_Garamond'] text-center text-3xl md:text-4xl mb-4 md:mb-6 font-bold">
                            Organizers
                        </Typography>
                        <Typography variant='h3' className="text-[#B40101] font-bold text-center  mx-auto text-2xl md:text-4xl">
                            Meet Our Lovely Team Member
                        </Typography>
                    </div>

                    <div className='md:grid grid-cols-3 md:justify-between items-stretch justify-center  hidden'>
                        {
                            memberArray?.map((ele, i) => <Member key={i} {...ele} />)
                        }
                    </div>
                    <div className='md:hidden' id='teamSwiper'>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={30}
                            loop={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay]}
                            className="mySwiper bg-inherit"
                        >

                            {
                                memberArray?.map((ele, i) => <SwiperSlide key={i}>
                                    <Member  {...ele} />
                                </SwiperSlide>
                                )
                            }
                        </Swiper>
                    </div>

                </div>
            </div>

        </section>
    )
}

export default Team