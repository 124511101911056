import React, { useEffect } from 'react'
import ListIcon from '../Assets/List → Item.png'
import HeaderSection from '../Components/HeaderSection/HeaderSection';

const provisionArray = [
    "All taxes including the service tax will be charged extra as applicable.",
    "Notice of cancellation must be made in writing to The Bliss Events.",
    "Within 7 days after the advance amount is received, if the event is cancelled by the client, then the client will be entitled to 50% of the total paid amount, less any supplier cancellation charges.",
    "Within 15 days after the advance amount is received, if the event is cancelled by the client then the client will be entitled to 20% of the total paid less any supplier cancellation charges.",
    "After 15 days, if the event is canceled by the client then, will not be entitled to get back any of the total paid less any supplier cancellation charges.",
    "Please note that irrespective of the above-mentioned cancellations slabs, there would be a service charge of 10% which would be applicable in case of cancellation of services after the booking is made with us.",
    "The Bliss Events is not liable for any interruption or disturbance/cancellation in the event/program due to Natural Disaster/Riots/Fire/Flood/Cyclone/Curfew etc.",
    "In concern to refund of unused / un-utilized services (which are paid for and canceled in advance) the refund amount would be calculated on the basis of cancellation policy as explained above and the money would be accordingly refunded to the person who has made the payment to us. Please note that it may take 4 – 8 weeks for the refund process due to banking procedures. If the refund is made to the credit card account or to Bank account, the bank charges would be debited from the refund amount.",
]


const TermsOfServices = () => {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <div className='max-w-[1600px] m-auto'>
            <HeaderSection title="Terms Of Service" />
            <div className='lg:w-10/12 w-11/12 m-auto flex flex-col gap-4 py-16'>
                <h2 className='md:text-2xl text-lg font-bold text-[#B03232] '>
                    Legal Disclaimer
                </h2>
                <p className='text-[#323232] md:text-base text-sm'>
                    This Legal Disclaimer governs the use of The Bliss Event's website and services. By accessing or using our website and services, you agree to the terms outlined in this disclaimer. The information provided on this website is for general informational purposes only. We strive to ensure the accuracy, timeliness, and completeness of the information provided; however, we make no representations or warranties of any kind, express or implied, about the accuracy, reliability, suitability, or availability concerning the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is strictly at your own risk.
                </p>
                <h2 className='md:text-2xl text-lg font-bold text-[#B03232] '>
                    Credit Reporting Terms of Service</h2>
                <p className='text-[#323232] md:text-base text-sm'>
                    The Bliss Events specializes in curating memorable and magical weddings for our cherished clients. In order to ensure transparency, trust, and efficient financial transactions, we outline the following Credit Reporting Terms of Service. 1. Credit Reporting Overview: a. Credit Scores: As a wedding planning company, 'THE BLISS EVENTS' acknowledges the importance of maintaining a positive credit score. We understand that our creditworthiness can influence our business relationships and financial transactions with vendors. b. Credit Reports: We value accurate credit reporting and regularly monitor our credit reports. This helps us to review and rectify any discrepancies, ensuring the accuracy of our financial history. 'THE BLISS EVENTS' values transparency, integrity, and reliability in all our business dealings. By abiding by these Credit Reporting Terms of Service, we aim to maintain a positive credit standing, foster strong relationships with our vendors, and ensure seamless financial transactions in our pursuit to create unforgettable weddings for our clients.
                </p>

                <h2 className='md:text-2xl text-lg font-bold text-[#B03232] '>
                    Ownership of Site Agreement to Terms of Use
                </h2>
                <p className='text-[#323232] md:text-base text-sm'>
                    By accessing and using this website, you acknowledge that you have read, understood, and agreed to comply with the terms outlined in this Ownership of Site Agreement and the Terms of Use. All content, including but not limited to text, images, graphics, logos, audio clips, video clips, and software, displayed or accessible on this Site, is the property of The Bliss Events or its licensors and is protected by copyright, trademark, and other intellectual property laws. If you have any questions or concerns regarding this Ownership of Site Agreement or the Terms of Use, please feel free to contact us at +91-9545717217.
                </p>
                <h2 className='md:text-2xl text-lg font-bold text-[#B03232] '>
                    Provision of Services
                </h2>
                <p className='text-[#323232] md:text-base text-sm'>
                    This Provision of Services Agreement sets forth the terms and conditions under which The Bliss Events agrees to provide services to the client.
                </p>
                <div>
                    {
                        provisionArray?.map((title) => <ListItem key={title} title={title} />)
                    }
                </div>

                <h2 className='md:text-2xl text-lg font-bold text-[#B03232] '>
                    Limitation of Liability
                </h2>
                <p className='text-[#323232] md:text-base text-sm'>
                    The Limitation of Liability clause within our agreements at The Bliss Events is a protective measure that defines the extent of our responsibility in case of unforeseen situations. While we strive to deliver exceptional services, this clause outlines that we are not liable for indirect, consequential, or incidental damages arising from the use of our services or website. At The Bliss Events, our commitment remains unwavering in delivering exceptional service, and this clause serves as a framework to ensure a fair and balanced working relationship for both parties involved.
                </p>
                <h2 className='md:text-2xl text-lg font-bold text-[#B03232] '>
                    Accounts, Passwords and Security
                </h2>
                <p>
                    Protecting your account at The Bliss Events is paramount. We prioritize security by encouraging users to safeguard their account credentials diligently. Creating robust passwords and keeping them confidential is essential to prevent unauthorized access. We employ industry-standard security measures, yet users should remain vigilant against potential threats. If any suspicious activity is noticed, we urge immediate reporting to our support team. Our commitment to security extends to educating users about phishing risks and ensuring compliance with our security policies. At The Bliss Events, your account's safety is a top priority, and together, we work towards a secure and trusted environment.
                </p>

            </div>

        </div>
    )
}

export default TermsOfServices;


const ListItem = ({ title }) => {

    return (
        <div className='flex items-center gap-2'>
            <img src={ListIcon} alt={ListIcon} className='w-5' />
            <p className='text-[#323232] md:text-base text-sm'>
                {title}
            </p>
        </div>
    )
}