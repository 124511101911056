import React, { useEffect } from 'react'

import HeaderSection from '../Components/HeaderSection/HeaderSection'
import ServiceForm from '../Components/LandingPage/ServiceForm/ServiceForm'
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GA_ID);

const BookAppointment = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page:window.location.pathname, title: "Book Appoinment Page" });
        window.scroll(0, 0)
    }, [])
    return (
        <div>
            <HeaderSection title={" Book Appointment"} />
            <ServiceForm />
        </div>
    )
}

export default BookAppointment