import React from 'react'
import { ColorCode } from '../../../App'
import { IoIosArrowForward } from 'react-icons/io'
import DestinationImage from '../../../Assets/LandingPage/Categories/destination-wedding.webp'
import RoyalImage from '../../../Assets/LandingPage/Categories/royal-wedding.webp'
import TraditionalImage from '../../../Assets/LandingPage/Categories/traditional-wedding.webp'

import LeavesImg from '../../../Assets/LandingPage/OurServices/LeavesImg.svg'
import FlowerImg from '../../../Assets/LandingPage/OurServices/FlowerImg.svg'
import { Link } from 'react-router-dom';
import CustomCarousel from '../../CutomCarousel/CustomCarousel'


const ourServicesCardInfo = [
    {
        title: "Destination Wedding",
        description: "Discover stunning locales and explore the ideas worldwide perfectly suited for your dream wedding",
        image: DestinationImage,
        link:"/destination-wedding"
    },
    {
        title: "Royal Wedding",
        description: "From tropical paradises to historical landmarks, discover the perfect setting for your dream wedding",
        image: RoyalImage,
        link:"/royal-wedding"
    },
    {
        title: "Traditional Wedding",
        description: "From intimate ceremonies to extravagant receptions ensuring a flawless and memorable celebration",
        image: TraditionalImage,
        link:"/traditional-wedding"
    },
]


const Categories = () => {
    return (
        <div className="bg-[#f7e1e4] ">
            <div className='max-w-[1600px] m-auto pt-10 pb-5 md:pb-40 relative '>
                <div className='lg:w-10/12 w-11/12 m-auto text-center'>

                    <h1 className="lg:text-4xl md:text-3xl text-2xl font-['Cormorant_Garamond'] font-bold">Categories</h1>
                    <h1 className='lg:text-4xl md:text-3xl text-2xl text-[#B40101] lg:mt-3 mt-2'>See, What We Our Offering</h1>

                    <div className='hidden relative z-50 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:justify-between md:flex flex-col md:flex-row md:flex-wrap md:justify-evenly gap-y-40 justify-center mt-10'>
                        {
                            ourServicesCardInfo?.map((el, i) => (
                                <OurServicesCard el={el} key={i} />
                            ))
                        }
                    </div>
                    <div className='relative z-50  lg:grid-cols-3 lg:gap-x-6 lg:justify-between md:hidden flex-col md:flex-row md:flex-wrap md:justify-evenly gap-y-40 justify-center mt-10'>
                        <CustomCarousel cardType={"category"} swiperArray={ourServicesCardInfo} />
                    </div>

                    <div className='absolute md:right-12 md:bottom-0 bottom-0 right-0'>
                        <img src={FlowerImg} alt={"FlowerImg"} className='md:w-40 lg:w-48 w-32' />
                    </div>

                </div>

            </div>
        </div >
    )
}

export default Categories


const OurServicesCard = ({ el }) => {

    return (
        <div className='lg:w-auto md:w-[20rem] relative'>

            <div className='z-10'><img src={el.image} alt={el.title} className='w-full lg:h-52 2xl:h-80  z-20 rounded-2xl ' /></div>
            <div className='group lg:w-[80%] w-[92%] m-auto justify-between absolute left-1/2 transform -translate-x-1/2 lg:-translate-y-1/2 -translate-y-1/3 z-50 rounded-xl  bg-white text-left px-4 py-4 leading-8  hover:bg-[#B40101] !transition-all !duration-700 !ease-linear '>
                <h3 className=' 2xl:text-[21px] lg:text-[16px] text-black xl:text-lg font-bold group-hover:text-white !transition-all !duration-700 !ease-linear '>{el.title}</h3>
                <h5 className='text-xs lg:text-xs xl:text-sm text-[#323232] md:h-12 lg:h-20 2xl:h-16 group-hover:text-white !transition-all !duration-700 !ease-linear '>{el.description}</h5>
                {/* <Link className=' w-28 text-base lg:text-xs xl:text-base text-[#B03232] font-bold'>Read More </Link> */}

                <Link to={el.link} className='text-sm text-white bg-[#B40101] px-3 py-[6px] rounded-[4px] font-semibold group-hover:text-[#B40101] group-hover:!bg-white !transition-all !duration-700 !ease-linear '>Read More</Link>

            </div>
            <div>
            </div>
        </div>
    )
}