import React, { useEffect } from 'react'
import ListIcon from '../Assets/List → Item.png'
import HeaderSection from '../Components/HeaderSection/HeaderSection';
import { title } from '@uiw/react-md-editor';
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GA_ID);

const listArray = [
    "Essential Cookies: Necessary for the basic functionality of our website. They enable features such as page navigation and access to secure areas.",
    "Analytical/Performance Cookies: Allow us to recognize and count the number of visitors, analyze web traffic, and improve our website's performance. This data is aggregated and anonymized",
    "Functionality Cookies: Enhance your experience by remembering your preferences customersupport@theblissevents.in . For instance, they may remember your language or region selection.",
    "Targeting/Advertising Cookies: These cookies may be set through our site by advertising partners. They are used to build a profile of your interests and show you relevant ads on other sites.",
]

const yourRightsArray = [
    "You have the right to access your personal data held by Bliss Events upon request.",
    "You can request the correction of any inaccuracies in your personal information.",
    "You have the right to request the deletion of your personal data when it is no longer needed.",
    "Manage your preferences for cookies used on our website through your browser settings.",
    "Upon request, receive your personal data in a commonly used and machine-readable format."

]

const PrivicyPolicy = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Privicy policy Page" });
        window.scroll(0, 0)
    }, [])
    return (
        <div className='max-w-[1600px] m-auto'>
            <HeaderSection title="Privacy Policy" />
            <div className='lg:w-10/12 w-11/12 m-auto flex flex-col gap-4 py-16'>
                <h2 className='md:text-2xl text-lg font-bold text-[#B03232] '>
                    Information Collection
                </h2>
                <p className='text-[#323232] md:text-base text-sm'>
                    At Bliss Events, we are committed to safeguarding your privacy. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information. By engaging with our services, you consent to the practices described in this policy.
                    <ul className=' list-disc w-[97%] mx-auto mt-2 flex flex-col gap-2'>
                        <li>
                            Contact Information: Names, email addresses, phone numbers.
                        </li>
                        <li>
                            Event Details: Wedding date, venue, preferences.
                        </li>
                        <li>
                            Billing Information: Invoicing and payment details.
                        </li>
                        <li>
                            Communication: Correspondence and feedback.
                        </li>
                    </ul>
                </p>
                <h2 className='md:text-2xl text-lg font-bold text-[#B03232] '>
                    How We Use Cookies</h2>
                <p className='text-[#323232] md:text-base text-sm'>
                    At Bliss Events, we use cookies to enhance your browsing experience and provide personalized services. Cookies are small text files stored on your device that help us analyze website usage, improve functionality, and tailor content to your preferences.
                </p>
                <div>
                    {
                        listArray?.map((ele, i) => <ListItem key={i} title={ele} />)
                    }
                </div>
                <h2 className='md:text-2xl text-lg font-bold text-[#B03232] '>
                    Information Sharing
                </h2>
                <p className='text-[#323232] md:text-base text-sm'>
                    We do not sell, trade, or transfer your personal information to third parties. Information may be shared with vendors only for event-related purposes with your consent.
                </p>
                <h2 className='md:text-2xl text-lg font-bold text-[#B03232] '>Data Protection</h2>
                <p className='text-[#323232] md:text-base text-sm'>
                    <ul className='list-disc w-[97%] mx-auto'>

                    </ul>
                    <li>
                        At Bliss Events, we are dedicated to safeguarding your privacy through our Data Protection Policy. We collect and process only essential personal data required for our wedding management services, ensuring it is handled securely and transparently. Our commitment extends to robust security measures, limited data sharing with explicit consent, and compliance with data protection laws.
                    </li>
                    <li>
                        You have the right to access, correct, or delete your information, and we retain data only as long as necessary. Our website utilizes cookies for an enhanced browsing experience, with options for managing preferences. We regularly review and update our practices to align with legal requirements, and any concerns or inquiries can be directed to{" "}
                        <a href="mailto:customersupport@theblissevents.in." className='underline'>
                            customersupport@theblissevents.in.
                        </a>
                    </li>
                    <li>
                        By choosing Bliss Events, you entrust us with your data, and we honor that trust by prioritizing your privacy every step of the way.
                    </li>
                </p>
                <h2 className='md:text-2xl text-lg font-bold text-[#B03232] '>
                    Your Rights
                </h2>
                <div className='text-[#323232] md:text-base text-sm'>
                    <p>
                        You have the right to access, correct, or delete your personal data. You can also opt out of promotional communications at any time.
                    </p>
                    {
                        yourRightsArray?.map((title) => <ListItem key={title} title={title} />)
                    }
                </div>
                <h2 className='md:text-2xl text-lg font-bold text-[#B03232] '>
                    Changes to the Cookie Policy
                </h2>
                <p className='text-[#54595F] md:text-base text-sm'>
                    We may update our cookie policy to reflect changes in our practices or for legal reasons. Any changes will be posted on this page. If you have any questions or concerns about our use of cookies, please contact us at customersupport@theblissevents.in .
                </p>
            </div>

        </div>
    )
}

export default PrivicyPolicy;

const ListItem = ({ title }) => {

    return (
        <div className='flex items-center gap-2 mt-2'>
            <img src={ListIcon} alt={ListIcon} className='w-5' />
            <p className='text-[#323232] md:text-base text-sm'>
                {title}
            </p>
        </div>
    )
}