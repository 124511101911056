import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

// import required modules
import { Pagination, Autoplay } from 'swiper/modules';

import TestimonialCard from './Card/TestimonialCard'
import { cleientFeedbackArray } from '../../../clientFeedback';




const Testimonial = () => {

  const [slidesPerView, setSlidesPerView] = useState(2)

  useEffect(() => {
    if (window.screen.width <= 720) {
      setSlidesPerView(1)
    } else {
      setSlidesPerView(2)
    }
  }, [window.screen.width])


  return (
    <section className='max-w-[1600px] mx-auto'>
      <div className='w-11/12 mx-auto lg:w-10/12 my-10'>
        <div className='text-center flex flex-col items-center gap-y-5'>
          <h2 className="font-['Cormorant_Garamond'] font-bold lg:text-3xl text-xl">
            Our Testimonial
          </h2>
          <h5 className='text-[#B40101] lg:text-4xl text-2xl'>
            Our Client's Feedback
          </h5>
        </div>
        <div className='max-w-[1200px] mx-auto overflow-x-hidden my-5 '>
          <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={30}
            autoplay={{
              delay: 2500
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Autoplay]}
            className="mySwiper  !pb-10"

          >
            {
              cleientFeedbackArray?.map((ele, i) => <SwiperSlide key={i}>
                <TestimonialCard  {...ele} />
              </SwiperSlide>
              )
            }

          </Swiper>
        </div>

      </div>
    </section >
  )
}

export default Testimonial
