import React, { useEffect } from 'react'

import HeaderSection from '../Components/HeaderSection/HeaderSection'
import Categories from '../Components/Services/Categories'
import ContentPage from '../Components/Services/ContentPage'
import OurServices from '../Components/Services/OurServices'

import Image1 from '../Assets/Svg_Image/Makeup/makeup-1.svg'
import Image2 from '../Assets/Svg_Image/Makeup/makeup-2.svg'
import Image3 from '../Assets/Svg_Image/Makeup/makeup-3.svg'
import Image4 from '../Assets/Svg_Image/Makeup/makeup-4.svg'

import ImageP_1 from '../Assets/Svg_Image/Makeup/makeup-2_1.svg'
import ImageP_2 from '../Assets/Svg_Image/Makeup/makeup-2_2.svg'
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GA_ID);

const page = {
    title: "Bride Make Up",
    mainTitle: "Transforming Dreams into Bridal Beauty",
    swiperArray: [Image1, Image2, Image3, Image4],
    ImageP_1, ImageP_2,
    solutionForCoupels_p: `With skillful hands and compassionate hearts, we weave magic, sculpting the canvas of the bride’s face into a masterpiece. Each stroke of our brushes is infused with intention, delicately highlighting her natural beauty and magnifying her glow. 
    Our Bridal Makeup services are more than just an application of cosmetics; they are a celebration of inner radiance, designed to accentuate the beauty that resides within. `,
    solutionForCoupels_p2: "We strive to create a look that transcends trends, a timeless elegance that will be cherished in photographs for years to come. Whether it's a classic, ethereal glow or a bold, modern statement, our goal is to ensure that the bride feels empowered and confident in her own skin. Our Bridal Makeup services are a tribute to the emotional significance of this milestone. It’s about unveiling the bride's inner glow, creating a visage that radiates not just beauty but the joy and love that overflow on this momentous day.",
    about_p: "With a blend of expertise and empathy, we walk alongside the bride, understanding her nerves, her excitement, and her dreams. We offer not just makeup services but an emotional sanctuary, a space where she can breathe, feel supported, and embrace the emotions that flood this pivotal day. Our dedication is to ensure that when the bride looks in the mirror, she sees not only flawless makeup but also a reflection of her confidence, her anticipation, and the immense love she holds within. It’s about making her feel empowered to embrace this monumental step with grace and poise.",
    aboutPointArray: [
        "Bridal Party Makeup Services",
        "Customized Makeup Design",
        "Trial Makeup Sessions",
        "Bridal Makeup Application",
        "Makeup Touch-Up Services",
    ],
    getFromThisService: "Each stroke of our brushes is infused with care and intention, aiming to not just enhance your beauty but to encapsulate the essence of your emotions. We understand that your day isn’t just about makeup—it's about love, joy, and the emotional whirlwind of a significant milestone.",
    getFromThisService_Ponints: [
        "Makeup Application: Our skilled artists delicately apply makeup, using premium products to accentuate your natural beauty while reflecting the emotions you hold dear.",
        "Bridal Confidence Cultivation: Beyond the cosmetic application, our services aim to cultivate confidence. We want the bride to feel empowered, exuding confidence and poise.",
        "Customized Eyelash Application: Adding a touch of glamour and depth to the bride's eyes with individualized eyelash enhancements that complement her look.",
        "Sensitive Skin Consideration: Paying careful attention to any skin sensitivities or allergies, using products and techniques that ensure comfort and safety for the bride.",
        "Post-Wedding Makeup Removal Guidance: Offering guidance and products for gentle makeup removal, ensuring the bride can transition from her glorious day to a peaceful evening with ease.",
    ]
}

const MakeUpPage = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Make up Page" });
        window.scroll(0, 0)
    }, [])
    return (
        <section >
            <HeaderSection title={page.title} />
            <div className="flex justify-evenly items-start lg:flex-row flex-col-reverse max-w-[1600px] mx-auto">
                <Categories />
                <ContentPage {...page} />
            </div>
            <OurServices />
        </section>
    )
}

export default MakeUpPage