import React from 'react'
import { Link } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io'
import ScrollTrigger from "react-scroll-trigger";



import weedingEntry from '../../../Assets/ServicesPage/weeding-entry.svg'
import haldi from '../../../Assets/ServicesPage/haldi.svg'
import weeding from '../../../Assets/ServicesPage/weeding.png'


import LeavesImg from '../../../Assets/LandingPage/OurServices/LeavesImg.svg'
import FlowerImg from '../../../Assets/LandingPage/OurServices/FlowerImg.svg'


const ourServicesCardInfo = [
    {
        title: "Reception",
        description: "From intimate ceremonies to extravagant receptions, our experienced planners tailor every detail to your preferences",
        image: weedingEntry,
        link: "/service/reception",
        // icon: WeddingIcon
    },
    {
        title: "Photography",
        description: "View our gallery showcasing breathtaking moments from our past weddings",
        image: weeding,
        link: "/service/photography",
        // icon: PhotographyIcon
    },
    {
        title: "Haldi",
        description: "Dive into our featured weddings, each unique and filled with love",
        image: haldi,
        link: "/service/haldi",
        // icon: BridalIcon
    },
];


const OurServices = () => {

    const [trigger, setTrigger] = React.useState(false)

    return (
        <div className="bg-white ">


            <div className='max-w-[1600px] m-auto pt-10 md:pb-72 pb-32 relative '>
                <div className='lg:w-10/12 w-11/12 m-auto text-center'>

                    <h1 className="lg:text-4xl md:text-4xl text-3xl font-['Cormorant_Garamond'] font-bold">Our Services</h1>
                    <h1 className='lg:text-5xl md:text-4xl text-3xl font-bold text-[#B40101] lg:mt-3 mt-2'>
                        We're Providing Best Services
                    </h1>
                    <ScrollTrigger
                        onEnter={() => setTrigger(true)}
                        onExit={() => setTrigger(false)}>
                        <div className='relative z-50 xl:grid xl:grid-cols-3 lg:justify-between flex flex-col md:flex-row md:flex-wrap md:justify-evenly md:gap-x-16 gap-y-10 justify-center mt-10'>
                            {
                                ourServicesCardInfo?.map((el, i) => (
                                    <AnimationServicesCard {...el} key={i} trigger={trigger} />
                                ))
                            }
                            <div className='md:block hidden absolute lg:-top-20 md:-top-28 -left-4 w-40 -z-50'>
                                <img src={LeavesImg} alt={LeavesImg} className='lg:w-16 w-14 ' />
                            </div>
                        </div>
                        <div className='md:hidden block absolute bottom-0 left-4'>
                            <img src={LeavesImg} alt={"LeavesImg"} className='w-20 ' />
                        </div>
                        <div className='absolute md:right-12 md:bottom-[6.3rem] bottom-0 right-0'>
                            <img src={FlowerImg} alt={"FlowerImg"} className='md:w-40 lg:w-48 w-32' />
                        </div>
                    </ScrollTrigger>

                </div>

            </div>
        </div >
    )
}

export default OurServices


const OurServicesCard = ({ el }) => {

    return (
        <div className='lg:w-auto md:w-[20rem] relative'>

            <div className='z-10'><img src={el.image} alt={el.title} className='w-full lg:h-52 2xl:h-72  z-20 rounded-2xl ' /></div>
            <div className='lg:w-[80%] w-[92%] m-auto justify-between absolute  left-1/2 transform -translate-x-1/2 lg:-translate-y-1/2 -translate-y-1/3 z-50 rounded-xl border border-[#B40101] bg-white text-left px-4 py-5'>
                <h3 className=' xl:text-[21px] lg:text-[16px] text-[#B40101] font-bold'>{el.title}</h3>
                <div className='flex justify-between mt-1'>
                    <div className='flex flex-col gap-3'>
                        <h5 className='text-xs lg:text-[10px] xl:text-sm text-[#323232] md:h-16 lg:h-20 xl:h-24'>
                            {el.description}
                        </h5>
                        <Link className='flex gap-2 items-center w-32 text-base lg:text-xs xl:text-base'>Read More <IoIosArrowForward /></Link>
                    </div>
                    <div className='w-28 lg:w-20 xl:w-28 flex justify-end items-end'>
                        <img src={el.icon} alt={el.icon} />
                    </div>
                </div>

            </div>
        </div>
    )
};


const AnimationServicesCard = ({ title, image, description, link = "/", trigger }) => {
    return (
        <div className='bg-[#B40101] flex-col flex justify-between rounded-xl py-3 gap-y-4 group w-10/12  md:w-auto max-w-[18rem] !transition-all !duration-1000 !ease-linear items-start px-5 relative h-52 mx-auto'>
            {/* <div>

            </div> */}
            <p className={`font-['Lemon']   relative text-white !transition-all !duration-300 !ease-linear md:self-center ${trigger && '!self-start md:!translate-x-1'}  self-start   md:-translate-x-1 `}>
                {title}
            </p>
            <p className={`text-left line-clamp-6 text-xs w-8/12  md:opacity-0  text-white ${trigger && "md:!opacity-100"}`}>
                {description}
            </p>
            <div className={`absolute  top-1/2 -translate-y-20 md:-translate-y-[40%] left-1/2 md:-translate-x-1/2 translate-x-10 sm:translate-x-16 ${trigger && "md:translate-x-10 xl:translate-x-16 md:-translate-y-20 "}  !transition-all !duration-500 !ease-linear   `}>
                <img src={image} alt={title} className='  !transition-all !duration-1000 !ease-linear w-32' />
            </div>
            <div className={`md:opacity-0 ${trigger && "md:!opacity-100"}`} >
                <Link to={link} className='bg-white text-[#B40101] text-xs rounded-md self-end justify-self-end px-2 py-1'>
                    Read More
                </Link>

            </div>
        </div>
    )
}

