import React from 'react'
import { Swiper, SwiperSlide, } from 'swiper/react'
import { Autoplay, EffectFade } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/effect-fade';





const SmallSwiper = ({ title1, title2, detailsArray1, detailsArray2, list, smallSwiperArray }) => {
    return (
        <section className='max-w-[1600px] mx-auto w-11/12 xl:w-10/12 mt-8'>
            <div className='flex justify-between items-start lg:flex-row flex-col-reverse'>
                <div className='lg:w-[54%] w-full '>
                    <div>

                        <h2 className='text-[#051145] font-semibold md:text-[26px] text-xl font-["Lexend"] hidden lg:block'>
                            {title1}
                        </h2>
                        <div className='text-[#54595F] text-sm  flex-col gap-y-5 mt-5 hidden lg:flex'>
                            {
                                detailsArray1?.map((ele, i) => <p key={ele}>{ele}</p>)
                            }
                        </div>
                        {
                            list && (
                                <ul className=' list-disc hidden lg:flex justify-start gap-x-10 gap-y-5 w-11/12 mx-auto flex-wrap mt-5 text-sm'>
                                    {
                                        list?.map((ele) => (
                                            <li key={ele}>{ele}</li>
                                        ))
                                    }
                                </ul>

                            )
                        }
                    </div>
                    <div className='mt-8'>
                        <h2 className='text-[#051145]  font-["Lexend"] font-semibold md:text-[26px]  text-xl'>
                            {title2}
                        </h2>
                        <div className='text-[#54595F] text-sm flex flex-col gap-y-5 mt-5'>
                            {
                                detailsArray2?.map((ele, i) => <p key={ele}>{ele}</p>)
                            }
                        </div>
                    </div>
                </div>
                <div className='lg:w-5/12 md:w-8/12 w-full '>
                    <Swiper
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay, EffectFade]}
                        effect={'fade'}
                        className='mySwiper'
                    >
                        {
                            smallSwiperArray?.map((ele, i) => <SwiperSlide key={i}>
                                <img src={ele.img} alt={ele.alt} className=' aspect-square object-cover' />
                            </SwiperSlide>)
                        }

                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default SmallSwiper