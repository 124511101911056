import React, { useEffect } from 'react'
import OurServices from '../Components/LandingPage/OurServices/OurServices'
import About from '../Components/LandingPage/About/About'
import DreamMarriage from '../Components/LandingPage/DreamMarriage/DreamMarriage'
import Team from '../Components/LandingPage/Team/Team'
import ServiceForm from '../Components/LandingPage/ServiceForm/ServiceForm'
import MakeAnInquiry from '../Components/LandingPage/MakeAnInquiry/MakeAnInquiry'
import Categories from '../Components/LandingPage/Categories/Categories'
import Testimonial from '../Components/LandingPage/Testimonial/Testimonial'
import Blogs from '../Components/LandingPage/Blogs/Blogs'
import Herosection from '../Components/LandingPage/Herosection/Herosection'
import HowWeWork from '../Components/LandingPage/HowWeWork/HowWeWork'
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GA_ID);

const LandingPage = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Home Page" });
        window.scroll(0, 0)
    }, [])
    return (
        <>
            <Herosection />
            <About />
            <OurServices />
            <MakeAnInquiry negativeMargin={true} marginBottom={false} />
            <DreamMarriage  />
            <Team />
            <ServiceForm />
            <Categories />
            <HowWeWork />
            <Testimonial />
            <Blogs />
        </>
    )
}

export default LandingPage