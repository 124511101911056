import React, { useState } from 'react'
import preWed from '../../Assets/LandingPage/preWedShoot1.webp';
import preWed2 from '../../Assets/LandingPage/preWedShoot2.webp';
import LockIcon from '../../Assets/FAQ/span.promo-icon.svg';
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
} from "@material-tailwind/react";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import HeaderSection from '../HeaderSection/HeaderSection';


const num = [
    {
        title: "How can I communicate with Bliss Events during the planning process?",
        desc: "Call our dedicated phone line +91-9545717217 to speak directly with our team or Drop us an email at customersupport@theblissevents.in with your inquiries or details, and we'll promptly respond.",
    },
    {
        title: "What is Bliss Events' cancellation policy?",
        desc: "Bliss Events' cancellation policy varies based on timing and services. We request early notice and handle cancellations case by case. Refunds, if applicable, adhere to the terms in our contract. For specifics, refer to your agreement or contact us directly.",
    },
    {
        title: "Do I Need To Make An Appointment At The Wedding?",
        desc: "Yes, it's recommended to make an appointment for the wedding. Scheduling ensures our availability and allows us to dedicate focused time to discuss and plan your special day according to your preferences and needs.",
    },
    {
        title: "How do I book Bliss Events for my wedding?",
        desc: "Contact us via phone or email to check availability and schedule a consultation. Finalize details, sign an agreement, and secure your booking for a seamless wedding planning experience with Bliss Events!",
    },

];

const Questions = () => {
    const [open, setOpen] = React.useState(1);
    const handleOpen = (value) => setOpen(open === value ? 0 : value);
    return (
        <section className='max-w-[1600px] mx-auto'>
            <div className='w-11/12 mx-auto lg:w-10/12'>
                <div className='flex lg:flex-row flex-col items-start justify-between gap-20 pt-12 pb-20'>
                    <div className='lg:w-[45%] w-full flex items-start relative'>
                        <div className='self-end flex flex-col items-center translate-y-10 md:translate-x-14  translate-x-10 -ml-8 md:-ml-12 lg:ml-0'>
                            <img src={LockIcon} alt="lock icon" className='w-12 hidden md:block z-50' />
                            <img src={preWed2} alt="pre wedding shoot" className='rounded-lg -mt-3 z-10' />
                        </div>
                        <div>
                            <img src={preWed} alt="pre wedding shoot large" className='rounded-lg' />
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full mx-auto flex flex-col lg:gap-2 gap-1">
                        <h1 className=" lg:text-4xl md:text-3xl text-xl font-medium text-[#B40101] font-['Cormorant_Garamond']">FAQ</h1>
                        <h1 className=" lg:text-4xl md:text-3xl text-xl font-bold mt-2">Need To Ask Some Questions</h1>
                        <div>
                            {
                                num?.map((el, i) => (
                                    <AccordionCustom open={open} setOpen={setOpen} handleOpen={handleOpen} id={i + 1} {...el} />
                                ))
                            }
                        </div>

                    </div>
                </div>

            </div>
        </section>
    )
};

export default Questions


const AccordionCustom = ({ open, setOpen, handleOpen, id, title, desc }) => {


    return (

        <Accordion open={open === id} icon={
            id === open
                ? (<AiOutlineMinusCircle className='bg-[#b40101] text-white rounded-full md:w-8 md:h-8 w-7 h-7 ' />)
                : (<AiOutlinePlusCircle className='text-[#b40101] md:w-8 md:h-8 w-7 h-7 ' />)
        } className=" -ml-4">
            <AccordionHeader onClick={() => handleOpen(id)} className="!flex-row-reverse !justify-end gap-4 !border-none !pl-0 md:text-base text-sm text-black font-bold">
                {title}
            </AccordionHeader>
            <AccordionBody className="w-11/12 ms-auto pt-0 text-sm">
                {desc}
            </AccordionBody>
        </Accordion>

    );
}