import React from 'react'
import { Progress, Typography } from '@material-tailwind/react'
import { Link } from 'react-router-dom'



import weddingimg from '../../../Assets/AboutUSPage1/Section → appoint-img-3.jpg.webp';
import weddingimg2 from '../../../Assets/AboutUSPage1/Section → appoint-img-4.jpg.webp';
import lock from '../../../Assets/AboutUSPage1/lockicon.svg'

// import collectionLines from '../../../Assets/ContactUs/roundStripCollection.svg'



const AboutBlissTwo = () => {
    return (
        <section className=' relative bg-[#FF477E12] md:py-10 py-6 overflow-y-hidden'>
            <div className='lg:w-10/12  w-11/12 max-w-[1600px] mx-auto'>
                <div className='flex justify-between items-center lg:mt-28 mt-12 mb-12 flex-col md:flex-row'>
                    <div className='md:w-1/2 flex justify-normal md:gap-x-4 items-end self-start sm:w-full'>
                        <div className='flex flex-col relative '>
                            <div className='border bg-white rounded-lg border-[#b40101] w-max lg:px-4 lg:py-2 px-2 py-1  font-semibold text-[#b40101] flex justify-around items-center  self-end -translate-x-2 md:translate-x-16 translate-y-10'>
                                <img src={lock} alt="lock Ring" className='!h-10 lg:h-auto' />
                                <Typography variant='paragraph' className='font-semibold text-center text-xs lg:text-base ' >
                                    100+ <br />Completed Project
                                </Typography>
                            </div>
                            <img src={weddingimg2} alt="wedding pic " className='-translate-x-2 md:translate-x-16 translate-y-10 rounded-xl' loading='lazy' />

                        </div>
                        <div className='self-start'>
                            <img src={weddingimg} alt="wedding pic" className='rounded-xl ' loading='lazy' />
                        </div>
                    </div>
                    <div className='self-start lg:w-5/12 md:w-[48%] w-full flex flex-col lg:gap-y-8 gap-y-2 mt-16 md:mt-0 '>
                        <Typography variant='h2' className='text-[#B40101] font-bold text-2xl lg:text-4xl' >
                            About The Bliss Event
                        </Typography>
                        <div>
                            <Typography variant='h5' className='font-["Lexend"] font-medium  lg:text-2xl text-base text-[#051145]  leading-8' >
                                We Inspire You To Bring Your

                            </Typography>
                            <Typography variant='h5' className='font-["Lexend"] font-medium  lg:text-2xl text-base text-[#051145]  mt-0' >
                                Wedding In Real Life
                            </Typography>
                        </div>
                        {/* <Typography variant='paragraph' className='font-normal text-[#323232] leading-10 md:leading-6 ' >
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Facilis minus fuga maiores. Nobis illum beatae perferendis error rem consequatur iure repellendus cupiditate, fugiat laborum nihil. Atque libero ab facere vitae?
                        </Typography> */}

                        <div>
                            <div className="w-full font-['Lexend'] text-[#051145] mt-2">
                                <div className="mb-2 flex items-center justify-between gap-4 font-medium">
                                    <p className='lg:text-lg'>
                                        Accessories
                                    </p>
                                    <p>
                                        93%
                                    </p>
                                </div>
                                <div className='relative'>
                                    <div className='h-1 bg-black opacity-30 rounded-md' />
                                    <div className='h-1 bg-[#b40101] opacity- rounded-md absolute top-0' style={{ width: "93%" }} />
                                </div>
                            </div>
                            <div className="w-full font-['Lexend'] text-[#051145] mt-2">
                                <div className="mb-2 flex items-center justify-between gap-4 font-medium">
                                    <p className='lg:text-lg'>
                                        Hair Style
                                    </p>
                                    <p>
                                        94%
                                    </p>
                                </div>
                                <div className='relative'>
                                    <div className='h-1 bg-black opacity-30 rounded-md' />
                                    <div className='h-1 bg-[#b40101] opacity- rounded-md absolute top-0' style={{ width: "94%" }} />
                                </div>
                            </div>
                            <div className="w-full font-['Lexend'] text-[#051145] mt-2">
                                <div className="mb-2 flex items-center justify-between gap-4 font-medium">
                                    <p className='lg:text-lg'>
                                        Perfect Dress
                                    </p>
                                    <p>
                                        98%
                                    </p>
                                </div>
                                <div className='relative'>
                                    <div className='h-1 bg-black opacity-30 rounded-md' />
                                    <div className='h-1 bg-[#b40101] opacity- rounded-md absolute top-0' style={{ width: "98%" }} />
                                </div>
                            </div>
                        </div>

                        <div className='flex justify-end items-end lg:mt-20 mt-10'>
                            <Link to={'/'} className='text-xl md:text-2xl text-[#323232] font-semibold'>
                                Read More...
                            </Link>
                        </div>


                        {/* <div className='hidden md:block absolute -bottom-10 left-0  w-52 -z-10'>
                            <img src={collectionLines} alt="side tree flower" />
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutBlissTwo