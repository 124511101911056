import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";
import { IoIosArrowForward } from 'react-icons/io'
import { GoPerson } from 'react-icons/go'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "@material-tailwind/react";
import imageUrlBuilder from '@sanity/image-url';
import { client } from '../../sanity';
import Moment from 'react-moment';

import './CustomCarousel.css';

const CustomCarousel = ({ autoplayInterval = 4000, swiperArray = [], pagination = false, cardType }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % swiperArray.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + swiperArray.length) % swiperArray.length);
  };
  useEffect(() => {
    const autoplayTimer = setInterval(() => {
      nextSlide();
    }, autoplayInterval);

    return () => {
      clearInterval(autoplayTimer);
    };
  }, [currentIndex, autoplayInterval]);

  return (
    <div className="swiper-container sm:h-[570px] overflow-x-hidden">
      <button className="hidden absolute h-8 w-8  items-center justify-center top-[18px] left-5 text-black bg-[#ffc900] rounded-full z-[100]" onClick={prevSlide}>
        <HiArrowLeft size={20} />
      </button>
      <div className="swiper-track" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {
          cardType === 'ourService' && swiperArray?.map((el, index) => <OurServicesCard el={el} key={index} />)
        }
        {
          cardType === 'category' && swiperArray?.map((el, index) => <CategoryCard el={el} key={index} />)
        }
        {
          cardType === 'blog' && swiperArray?.map((ele, index) => <BlogCard {...ele} key={index} />)
        }
      </div>
      <button className="hidden absolute h-8 w-8  items-center justify-center top-[18px] right-5 text-black bg-[#ffc900] rounded-full z-[100]" onClick={nextSlide}>
        <HiArrowRight size={20} />
      </button>
      <div className='flex h-20 gap-x-10 justify-center items-center z-[100]'>
        <button className={`${cardType === 'category' || 'Blog' ?'bg-[#B40101] text-white':'bg-white text-[#051145]'}  p-4 rounded-full`} onClick={prevSlide}>
          <HiArrowLeft size={25} />

        </button>
        {
          pagination ? <h4 className='font-semibold w-12'>0{currentIndex + 1}<span className='text-[#AEAEAE]'>/0{swiperArray.length}</span></h4> : ""
        }
        <button className={`${cardType === "category" || "Blog" ?"bg-[#B40101] text-white":"bg-white text-[#051145]"}  p-4 rounded-full`} onClick={nextSlide}>
          <HiArrowRight size={22} />
        </button>
      </div>
    </div>
  );
};

export default CustomCarousel;


const OurServicesCard = ({ el }) => {
  return (
    <div className="swiper-slideer">
      <div className='lg:w-auto md:w-[20rem] relative'>
        <div className='z-10'><img src={el.image} alt={el.title} className='w-full lg:h-52 2xl:h-72  z-20 rounded-2xl ' /></div>
        <div className='lg:w-[80%] w-[92%] m-auto justify-between absolute  left-1/2 transform -translate-x-1/2 lg:-translate-y-1/2 -translate-y-1/3 z-50 rounded-xl border border-[#B40101] bg-white text-left px-4 py-5'>
          <h3 className=' xl:text-[21px] lg:text-[16px] text-[#B40101] font-bold'>{el.title}</h3>
          <div className='flex justify-between mt-1'>
            <div className='flex flex-col gap-3'>
              <h5 className='text-xs lg:text-[10px] xl:text-sm text-[#323232] md:h-16 lg:h-20 xl:h-24'>
                {el.description}
              </h5>
              <Link className='flex gap-2 items-center w-32 text-base lg:text-xs xl:text-base'>Read More <IoIosArrowForward /></Link>
            </div>
            <div className='w-28 lg:w-20 xl:w-28 flex justify-end items-end'>
              <img src={el.icon} alt={el.icon} />
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}


const CategoryCard = ({ el }) => {
  return (
    <div className="swiper-slideer">
      <div className='lg:w-auto md:w-[20rem] relative mb-28 '>
        <div className='z-10 '><img src={el.image} alt={el.title} className='w-full lg:h-52 2xl:h-72  z-20 rounded-2xl ' /></div>
        <div className='lg:w-[80%] w-[92%] m-auto justify-between absolute  left-1/2 transform -translate-x-1/2 lg:-translate-y-1/2 -translate-y-1/3 z-50 rounded-xl  bg-white text-left px-4 py-4 leading-8'>
          <h3 className=' xl:text-[21px] lg:text-[16px] text-black font-bold'>{el.title}</h3>
          <h5 className='text-xs lg:text-[10px] xl:text-sm text-[#323232] md:h-12 lg:h-20 mb-1'>{el.description}</h5>
          {/* <Link className=' w-28 text-base lg:text-xs xl:text-base text-[#B03232] font-bold'>Read More </Link> */}
          <Link to={el.link} className='text-sm text-white bg-[#B40101] px-3 py-[6px] rounded-[4px] font-semibold group-hover:text-[#B40101] group-hover:!bg-white !transition-all !duration-700 !ease-linear '>Read More</Link>
        </div>
      </div>
    </div>
  )
}

const BlogCard = ({ blogimage, title, createdAt, _id }) => {
  const builder = imageUrlBuilder(client)

  // <img src={`${builder.image(blogData[0]?.blogimage).url()}`} alt='blog-image' className='mt-8' />
  return (
    <div className="swiper-slideer">

      <Card className="md:max-w-[13rem] lg:max-w-[20rem] mx-auto overflow-hidden mb-4">
        <CardHeader
          floated={false}
          shadow={false}
          color="transparent"
          className="m-0 rounded-none relative overflow-visible"
        >

          <img src={`${builder.image(blogimage).url()}`} alt='blog-image' className=' object-cover aspect-[16/11]' />          
          <div className="bg-[#B40101] absolute lg:py-3 lg:px-4 px-2 py-1 text-white 
          right-4 -bottom-10">
            <div className="flex flex-col text-center">
              <Moment format="D" withTitle>
                {createdAt}
              </Moment>
              <Moment format="MMM" withTitle>
                {createdAt}
              </Moment>
            </div>
          </div>
        </CardHeader>

        <CardBody >
          <p className="flex gap-x-2 items-center text-[#323232]">
            <GoPerson className="text-[#B40101]" size={25} />Admin
          </p>
        </CardBody>
        <CardFooter className="!pt-2">
          <h3 className="text-black lg:text-xl md:text-sm text-lg mb-3 line-clamp-2">
            {title}
          </h3>
          <Link to={`/blog/${_id}`} className="text-[#B03232] font-bold " >
            Read More
          </Link>
        </CardFooter>
      </Card>
    </div>
  )
}
