import React, { useEffect } from 'react'

import HeaderSection from '../Components/HeaderSection/HeaderSection'
import AboutBliss from '../Components/AboutUs/About/AboutBliss'
import AboutBlissTwo from '../Components/AboutUs/AboutBlissTwo/AboutBlissTwo'
import OurCompletedProjects from '../Components/AboutUs/OurCompletedProjects/OurCompletedProjects'
import About from '../Components/LandingPage/About/About'
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GA_ID);

const AboutUsPage = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "About us" });
        window.scroll(0, 0)
    }, [])
    return (
        <>
            <HeaderSection title={"About Us"} />
            <AboutBliss />
            {/* <About/> */}
            <AboutBlissTwo />
            <OurCompletedProjects />

            <div className=' w-max-[1600px] md:w-10/12 w-11/812 m-auto mb-12'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7440.976845266355!2d79.04082814155888!3d21.17274784784713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c12b84ed7323%3A0xdf3341a583a98cba!2sThe%20Bliss%20Events%20-%20Wedding%20Planner%20%26%20Event%20Management%20Nagpur!5e0!3m2!1sen!2sin!4v1703741068271!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </>
    )
}

export default AboutUsPage