import React from "react";
import "./App.css";
import MainRoutes from "./AllRoutes/MainRoutes";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Categories from "./Components/Services/Categories";
import ContentPage from "./Components/Services/ContentPage";
import OurServices from "./Components/Services/OurServices";
import DestinationWedding from "./Pages/DestinationWedding";
import RoyalWedding from "./Pages/RoyalWedding";
import NewFooter from "./Components/Footer/NewFooter";
// ..
AOS.init({
  duration: 300,
  initClassName: "initial-anim",
  once: true,
  mirror: true,
  offset: 50,
  debounceDelay: 100,
  throttleDelay: 100,
});


function App() {
  return <div  >
    <MainRoutes />

  </div>;
}

export default App;
