import React from 'react'
import { Typography } from '@material-tailwind/react'
import { Link } from 'react-router-dom'
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

import ItemCard from './Card/Card'

import heart from '../../../Assets/LandingPage/AboutCard/Heart1.svg'
import clipboard from '../../../Assets/LandingPage/AboutCard/Icon.svg'
import wedding from '../../../Assets/LandingPage/AboutCard/HeartLock.svg'
import cake from '../../../Assets/LandingPage/AboutCard/Cake.svg'

import weddingimg from '../../../Assets/LandingPage/WeddingPhoto.webp'
import weddingimg2 from '../../../Assets/LandingPage/WeddingPhoto2.svg'
import treeBranch from '../../../Assets/LandingPage/Tree&flower/about_tree_branch.svg'
import Ring from '../../../Assets/LandingPage/EngagementRing.svg'
import Sidetree from '../../../Assets/LandingPage/SideTree.svg';

import familiesImg from '../../../Assets/LandingPage/AboutCard/heart.svg';
import yearsImg from '../../../Assets/LandingPage/AboutCard/mdi_heart.svg';
import projectImg from '../../../Assets/LandingPage/AboutCard/Mask group.svg';


const cardArray = [
    {
        img: heart,
        title: "MADE WITH LOVE",
        desc: "A beautiful destination where all your dreams for your special day will come true"
    },
    {
        img: cake,
        title: "JUST TWO OF US",
        desc: "From engagement to the grand day, our expert planners make every step seamless"
    },
    {
        img: wedding,
        title: "THE WEDDING BLISS",
        desc: "Capture every moment beautifully with our skilled professionals"
    },
    {
        img: clipboard,
        title: "decoration PLAN",
        desc: "To add a beautiful touch of your culture and taste of elegance"
    },
]

const About = () => {
    const [trigger, setTrigger] = React.useState(false)
    return (
        <section className=' relative max-w-[1600px] mx-auto '>
            <div className='lg:w-10/12 mx-auto w-11/12 md:mt-7 mt-4 '>
                <div className='lg:grid lg:grid-cols-4 grid grid-cols-2 xl:gap-10 lg:gap-4 md:gap-8 gap-x-4 md:flex md:flex-wrap  lg:justify-between justify-evenly  items-stretch'>
                    {
                        cardArray?.map((ele, i) => <ItemCard key={i} {...ele} i={i} />)
                    }
                </div>
                <div className='grid lg:grid-cols-2 grid-cols-1 gap-x-6 justify-between items-center md:mt-28 mt-20 mb-12 flex-col lg:flex-row'>
                    <div className='flex justify-end items-end sm:w-full'>
                        <div className='flex flex-col relative'>
                            <img src={treeBranch} alt="tree Branch" className='w-28 self-end ' />
                            <img
                                data-aos="fade-right"
                                data-aos-duration="1000"
                                data-aos-delay="250"
                                src={weddingimg2} alt="wedding pic " className='relative left-2 md:left-4 -top-10 rounded-xl z-10' loading='lazy' />
                            <div
                                data-aos="fade-right"
                                data-aos-duration="1000"
                                data-aos-delay="150"
                                className='border bg-white rounded-lg border-[#b40101] w-max lg:px-4 lg:py-2 px-2 py-1  font-semibold text-[#b40101] flex justify-around items-center absolute self-end  bottom-10 left-2/3 z-20 shadow-xl '>
                                <img src={Ring} alt="enagaement Ring" className='h-10 lg:h-auto' />
                                <Typography

                                    variant='paragraph' className='font-semibold text-center text-xs lg:text-base ' >
                                    1000+ <br />Wedding
                                </Typography>
                            </div>
                        </div>
                        <div>
                            <img
                                data-aos="fade-right"
                                data-aos-duration="1000"
                                src={weddingimg} alt="wedding pic" className='rounded-xl' loading='lazy' />
                        </div>
                    </div>
                    <div className='h-full  w-full flex flex-col justify-between lg:gap-y-3 gap-y-6 mt-5 lg:mt-0'>
                        <Typography variant='h2' className='text-[#B40101] font-bold uppercase text-2xl 2xl:text-5xl md:text-4xl' >
                            aBOUT bLISS eVENT
                        </Typography>
                        {/* <Typography variant='h5' className='font-normal  text-lg md:text-2xl  leading-[3.5rem] md:leading-8' >
                            We Inspire You To Bring We Inspire You To Bring Your Wedding In Real Life Your Wedding In Real Life
                        </Typography> */}
                        <Typography variant='paragraph' className=' text-[#323232]  md:leading-6 text-[15px] font-medium ' >
                            The Bliss Events embodies the art of transforming dreams into reality. With a dedicated team driven by innovation and an eye for detail, we transform visions into reality. Our journey is defined by a commitment to excellence, crafting bespoke experiences that capture the essence of every celebration.
                        </Typography>
                        <Typography variant='paragraph' className=' text-[#323232]  md:leading-6 text-[15px] font-medium ' >
                            From elegant weddings to mesmerizing events, we infuse creativity and expertise into every facet, ensuring each occasion is a masterpiece. At The Bliss Events, we embrace diversity, infuse elegance, and curate moments that linger in the hearts of our clients and their guests long after the event concludes.
                        </Typography>
                        <ScrollTrigger
                            onEnter={() => setTrigger(true)}
                        >
                            <div className='flex justify-between gap-x-4 md:justify-around lg:justify-between  items-start flex-wrap lg:flex-nowrap md:gap-y-2 gap-y-3'>
                                <div className=' flex items-center gap-x-3 '>
                                    <div className='w-10 h-10 flex justify-center items-center ' style={{ boxShadow: "0px 0px 10px 1px rgba(165,165,165,0.6)" }}>
                                        <img src={familiesImg} alt="families-image" className='w-5' />

                                    </div>
                                    <div className='flex justify-center flex-col items-start lg:w-max'>
                                        <p className=' font-semibold md:text-xl '>
                                            {
                                                trigger &&
                                                < CountUp start={0} end={150} duration={5} delay={0} />
                                            }+

                                        </p>
                                        <p className='xl:text-xs lg:text-[10px] text-[9px] font-semibold text-left md:w-full'>
                                            Families Happily Served
                                        </p>
                                    </div>
                                </div>
                                <div className=' flex items-center gap-x-3'>
                                    <div className='w-10 h-10 flex justify-center items-center ' style={{ boxShadow: "0px 0px 10px 1px rgba(165,165,165,0.6)" }}>
                                        <img src={yearsImg} alt="families-image" className='w-6' />

                                    </div>
                                    <div className='flex justify-center flex-col items-start lg:w-max '>
                                        <p className=' font-semibold md:text-xl'>
                                            {
                                                trigger &&
                                                < CountUp start={0} end={10} duration={5} delay={0} />
                                            }+

                                        </p>
                                        <p className='xl:text-xs lg:text-[10px] text-[9px] font-semibold text-left md:w-full'>
                                            Years of Experienced
                                        </p>
                                    </div>
                                </div>
                                <div className=' flex items-center gap-x-3'>
                                    <div className='w-10 h-10 flex justify-center items-center ' style={{ boxShadow: "0px 0px 10px 1px rgba(165,165,165,0.6)" }}>
                                        <img src={projectImg} alt="families-image" className='w-6' />

                                    </div>
                                    <div className='flex justify-center flex-col items-start lg:w-max'>
                                        <p className=' font-semibold md:text-xl '>
                                            {
                                                trigger &&
                                                < CountUp start={0} end={12} duration={5} delay={0} />
                                            }K

                                        </p>
                                        <p className='xl:text-xs lg:text-[10px] text-[9px] font-semibold text-leftmd:w-full'>
                                            Going projects
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </ScrollTrigger>

                        <div className='w-44 py-2 flex justify-center lg:self-auto self-center  md:ml-16 2xl:mt-0 md:mt-0    bg-[#B40101] rounded-lg !shadow-[0px_10px_60px_0px_rgba(255,125,125,1)]'
                        >
                            <Link to={'/about-us'} className='text-2xl text-white font-semibold'>
                                Read More
                            </Link>
                        </div>

                        <div className=' self-start place-content-start place-items-start absolute bottom-0 left-0 -translate-x-1/3 translate-y-1/2 -z-10'>
                            <img src={Sidetree} alt="side tree flower" />
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default About