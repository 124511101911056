import React from 'react';

import './style.css'

import SpeakIcon from '../../../Assets/LandingPage/HowWeWork/speak.svg';
import OptionIcon from '../../../Assets/LandingPage/HowWeWork/option.svg';
import PackageIcon from '../../../Assets/LandingPage/HowWeWork/package-outline.svg';
import BookIcon from '../../../Assets/LandingPage/HowWeWork/contact.svg';
import SmileIcon from '../../../Assets/LandingPage/HowWeWork/happy-face-line.svg';
import VectorLine from '../../../Assets/LandingPage/VectorLine.svg'
import VectorLineMobile from '../../../Assets/LandingPage/connector.png'

import WavePng from '../../../Assets/LandingPage/wave-png.svg'


const cardDetails = [
    {
        icon: SpeakIcon,
        title: "SPEAK TO US",
        description: "Give your Requirment & preferences wedding expert",
        iconBgColor: ""
    },
    {
        icon: OptionIcon,
        title: "See The Option",
        description: "Get a Walkthrough of our designs , pricing &service",
        iconBgColor: ""
    }, {
        icon: PackageIcon,
        title: "Get the package",
        description: "Receive a customized package based on your requirement",
        iconBgColor: ""
    },
    {
        icon: BookIcon,
        title: "Book Us",
        description: "Pay small booking fee to confirm once you are happy",
        iconBgColor: ""
    },
    {
        icon: SmileIcon,
        title: "Sit & Relax",
        description: "Sit back & Relax while we do all the work",
        iconBgColor: ""
    },
]

const HowWeWork = () => {
    return (
        <div className='max-w-[1600px]  m-auto pt-10 overflow-hidden'>
            <div className='w-11/12  lg:w-10/12 m-auto relative '>
                <div className='text-center'>
                    <h1 className="lg:text-7xl md:text-5xl text-2xl font-['Cormorant_Garamond'] font-extrabold text-[#B40101]">How We Work </h1>
                    <h1 className='lg:text-3xl md:text-3xl text-base lg:mt-6 mt-2'>5 Easy steps for a stress free wedding </h1>
                </div>
                <div className='flex justify-between items-center absolute w-full mt-8 md:mt-16 '>
                    {
                        cardDetails?.map((ele, i) => <div
                            key={ele.title}
                            className={`bg-[#3F51A0]  p-3 md:p-3 md:py-4 lg:px-5 lg:p-6 rounded-full z-50 circle_${i + 1}`}
                        >
                            <img src={ele.icon} alt={ele.title} className='w-8 h-8 md:w-12 md:h-12 lg:w-auto' />

                        </div>)
                    }
                </div>
            </div>
            <div className='w-full bottom-0 relative z-100'>
                <div className='absolute top-[55%] md:top-[65%] translate-y-1/3 right-1/2 translate-x-1/2 z-[200] w-full'>
                    {
                        cardDetails?.map((ele, i) => <div key={ele.description} className={`hidden_animation${i + 1} md:top-0 lg:top-16 top-16`}>
                            <p className='text-center md:text-xl xl:text-2xl font-semibold'>
                                {i + 1}. {ele.title}
                            </p>
                            <p className='text-center text-[#323232] text-[11px] md:text-lg xl:text-xl lg:mt-4 md:mt-4 mt-1'>
                                {ele.description}
                            </p>

                        </div>)
                    }
                </div>
                <img src={WavePng} alt="wave-png" className='relative z-100 bottom-0 md:h-auto h-[265px] object-cover ' />
            </div>


        </div>
    )
}

export default HowWeWork;

