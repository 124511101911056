import React from 'react'
import { Link } from 'react-router-dom';

// import { AnimationServicesCard } from '../LandingPage/OurServices/OurServices'

import CardImg1 from '../../Assets/ServicesPage/weeding-entry.svg'
import CardImg2 from '../../Assets/ServicesPage/weeding.png'
import CardImg3 from '../../Assets/ServicesPage/haldi.svg'
import CardImg4 from '../../Assets/ServicesPage/decorated-stage.svg'
import CardImg5 from '../../Assets/ServicesPage/makeup-bride.svg'
import CardImg6 from '../../Assets/ServicesPage/food-table.svg'

// import WeddingImg from '../../Assets/ServicesPage/wedding-reception.jpg'
// import WeddingIcon from '../../Assets/ServicesPage/wedding-icon.svg';

// import WeddingImg2 from '../../Assets/ServicesPage/weddingImge2.svg'
// import WeddingIcon2 from '../../Assets/ServicesPage/weddingIcon2.svg';

// import WeddingImg3 from '../../Assets/ServicesPage/weddingImge3.svg'
import WeddingIcon3 from '../../Assets/ServicesPage/weddingIcon3.svg';

// import WeddingImg4 from '../../Assets/ServicesPage/weddingImge4.svg'
import WeddingIcon4 from '../../Assets/ServicesPage/weddingIcon4.svg';

// import WeddingImg5 from '../../Assets/ServicesPage/weddingImge5.svg'
// import WeddingIcon5 from '../../Assets/ServicesPage/weddingIcon5.svg';

// import WeddingImg6 from '../../Assets/ServicesPage/weddingImge6.svg'
// import WeddingIcon6 from '../../Assets/ServicesPage/weddingIcon6.svg';





const cardData = [
  {
    title: "Reception",
    description: "Explore breathtaking global venues, ideal for your dream wedding. Discover stunning locales worldwide, ensuring the perfect backdrop for your celebration.",
    image: CardImg1,
    // weddingIcom: WeddingIcon,
    link: "/service/reception"
  },
  {
    title: "Photography",
    description: "Explore our featured weddings, each a unique and love-filled journey. Dive into unforgettable moments captured in these special celebrations.",
    image: CardImg2,
    // weddingIcom: WeddingIcon2,
    link: "/service/photography"


  },
  {
    title: "Haldi",
    description: "Celebrate love in our Anniversary Program, showcasing unique stories filled with joy, beauty, and special moments. Join the celebration!",
    image: CardImg3,
    // weddingIcom: WeddingIcon5,
    link: "/service/haldi"

  },
  {
    title: "Decoration",
    description: "Discover our featured wedding decorations—each unique, filled with love. Immerse yourself in the beauty and special moments of these celebrations.",
    image: CardImg4,
    weddingIcom: WeddingIcon4,
    link: "/service/decoration"
  },
  {
    title: "Makeup",
    description: "Explore our featured weddings, each a unique love story. Immerse yourself in the joy, beauty, and special moments of each celebration.",
    image: CardImg5,
    weddingIcom: WeddingIcon3,
    link: "/service/bridal-makeup"

  },
  {
    title: "Caterers",
    description: "Discover our featured pre-wedding receptions, uniquely crafted love stories filled with joy, beauty, and memorable moments. Dive in and enjoy!",
    image: CardImg6,
    // weddingIcom: WeddingIcon6,
    link: "/service/caterers"
  }];

const ServicesCard = () => {
  return (
    <div className='max-w-[1600px] m-auto'>
      <div className='overflow-x-clip lg:w-10/12 w-11/12 m-auto py-10 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 items-stretch  gap-x-6 gap-y-8'>
        {
          cardData?.map((ele, i) => (
            <AnimationServicesCard {...ele} key={i} />
          ))
        }

      </div>
    </div>
  )
}

export default ServicesCard


const Card = ({ title, desc, weddingImg, weddingIcom, link }) => {

  return (
    <div className='w-auto '>

      <div className='z-10 relative'>
        <img src={weddingImg} alt={title} className='w-full lg:h-52 2xl:h-64 object-cover  z-20 rounded-t-xl aspect-[15/10]' />
        <div className='absolute -bottom-4 left-4 p-3 border border-[#B40101] bg-white flex justify-end items-center'>
          <img src={weddingIcom} alt={title} className='w-8 as' />
        </div>
      </div>
      <div className='m-auto flex flex-col gap-2  border-[#B40101] shadow-xl rounded-b-xl text-left p-4 pt-6'>
        <h3 className=' xl:text-[21px] text-lg text-[#B40101] font-bold mt-1'>{title}</h3>
        <h5 className='text-xs md:h-16 xl:h-24 lg:text-[10px] xl:text-sm text-[#323232] '>
          {desc}
        </h5>
        <Link to={link} className='flex gap-2 items-center w-32 text-base lg:text-xs xl:text-base font-semibold text-[#B40101]'>Read More </Link>
      </div>
    </div>
  )
}

const AnimationServicesCard = ({ title, image, description, link = "/" }) => {
  return (
      <div className='bg-[#B40101] flex-col flex justify-between rounded-xl py-3 gap-y-4 group w-10/12  md:w-auto max-w-[18rem] !transition-all !duration-1000 !ease-linear items-start px-5 relative h-52 mx-auto'>
          {/* <div>

          </div> */}
          <p className="font-['Lemon']   relative text-white !transition-all !duration-300 !ease-linear self-center group-hover:self-start  -translate-x-1 group-hover:translate-x-1 ">
              {title}
          </p>
          <p className='text-left line-clamp-6 text-xs w-8/12 opacity-0 group-hover:opacity-100 text-white'>
              {description}
          </p>
          <div className='absolute  top-1/2 -translate-y-[40%] left-1/2 -translate-x-1/2 group-hover:translate-x-10 sm:group-hover:translate-x-16 md:group-hover:translate-x-10 xl:group-hover:translate-x-16  !transition-all !duration-500 !ease-linear  group-hover:-translate-y-20'>
              <img src={image} alt={title} className='  !transition-all !duration-1000 !ease-linear w-32' />
          </div>
          <div className='opacity-0 group-hover:opacity-100' >
              <Link to={link} className='bg-white text-[#B40101] text-xs rounded-md self-end justify-self-end px-2 py-1'>
                  Read More
              </Link>

          </div>
      </div>
  )
}