import React, { useState } from 'react'
import WeddingImage from '../../../Assets/LandingPage/MakeAnInquiry/weddind-image.jpg'
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { BsCalendar2Date } from 'react-icons/bs';


const MakeAnInquiry = ({ negativeMargin , marginBottom }) => {
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    }
    return (
        <div className='max-w-[1600px] m-auto md:my-0 my-6' >
            <div className={`lg:w-10/12 w-11/12  m-auto ${negativeMargin ? "md:-mt-44 -mt-8" : "md:mt-4"} ${marginBottom ? "xl:mb-72 lg:mb-80 md:mb-0" : "md:mb-52 mb-24"}`}>
                <div className='relative md:mb-56 xl:mb-36 mb-8' >
                    <img src={WeddingImage} alt={"WeddingImage"} className=' aspect-video lg:rounded-2xl  md:block hidden' />
                    <div className='lg:w-[26rem] h-full md:w-80 w-full md:absolute lg:top-28 top-10 lg:right-16 right-10  px-8 2xl:py-10 md:py-1 py-10 rounded-2xl  bg-white'
                        style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
                    >
                        {/* <div className='text-center mb-6'>
                            <h1 className='text-[#B40101] text-2xl '>Make An Inquiry </h1>
                            <h2 className='text-[#B40101] w-32 m-auto'>For your </h2>
                            <h2 className='text-[#B40101] font-bold w-32 m-auto'> Perfect Events</h2>
                        </div> */}
                        {/* <form action="" className='flex flex-col lg:gap-4 2xl:gap-6 gap-2'> */}
                        {/* <Input variant="standard" label="Name" className=' placeholder:text-[#B40101] px-2 placeholder-shown:border-[#B40101] !border-[#B40101] focus:!border-[#B40101]'
                                labelProps={{
                                    className: "peer-placeholder-shown:text-[#B40101]", color: "red"
                                }} /> */}

                        {/* <input type="text" placeholder='Name' name="" id="" className=' border-b border-[#B40101] text-[#B40101] outline-none px-3 py-2 placeholder:text-[#B40101]' />
                            <input type="text" placeholder='Email' name="" id="" className=' border-b border-[#B40101] text-[#B40101] outline-none px-3 py-2 placeholder:text-[#B40101]' />
                            <div className=''>

                                <DatePicker
                                    showIcon
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    dateFormat="MM/dd/yyyy"
                                    isClearable
                                    placeholderText="Wedding Date"
                                    className="relative w-full border-b border-[#B40101] text-[#B40101] !px-3 py-2 outline-none  placeholder:text-[#B40101]  "
                                    icon={<BsCalendar2Date className=' absolute right-4 top- z-50 text-[#b40101]' />}
                                // className="date-picker-right-icon"
                                />
                            </div>

                            <input type="text" placeholder='Number Of Guests' name="" id="" className=' border-b border-[#B40101] text-[#B40101] outline-none px-3 py-2 placeholder:text-[#B40101] ' />
                            <input type="text" placeholder='Meal Preferences' name="" id="" className=' border-b border-[#B40101] text-[#B40101] outline-none px-3 py-2 placeholder:text-[#B40101] ' />
                            <button className='py-2 bg-[#B40101] text-white w-44 m-auto text-lg font-semibold rounded-xl mt-6'>Send an Inquiry</button> */}
                        {/* </form> */}

                        <iframe
                            title="contact-form"
                            className='h-[590px] md:h-[582px] w-full overflow-hidden'
                            aria-label='Bliss Events Inquiry'
                            frameBorder="0"
                            src='https://forms.zohopublic.in/gbjbuzz/form/BlissEventsInquiry/formperma/ttFu_ETq0oy8QNClIvp2w1e5IU0brYA14rxGfNb-ZWM'>

                        </iframe>
                    </div>
                </div>

            </div>
        </div >
    )
}

export default MakeAnInquiry
