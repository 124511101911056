import React, { useEffect } from 'react'

import HeaderSection from '../Components/HeaderSection/HeaderSection'
import Categories from '../Components/Services/Categories'
import ContentPage from '../Components/Services/ContentPage'
import OurServices from '../Components/Services/OurServices'

import Image1 from '../Assets/Svg_Image/Photography/photography-1.svg'
import Image2 from '../Assets/Svg_Image/Photography/photography-2.svg'
import Image3 from '../Assets/Svg_Image/Photography/photography-3.svg'
import Image4 from '../Assets/Svg_Image/Photography/photography-4.svg'


import ImageP_1 from '../Assets/Svg_Image/Photography/photography-2_1.svg'
import ImageP_2 from '../Assets/Svg_Image/Photography/photography-2_2.svg'
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GA_ID);

const page = {
    title: "Photograph Event",
    mainTitle: "Capturing every beautiful moment of your special day",
    swiperArray: [Image1, Image2, Image3, Image4],
    ImageP_1, ImageP_2,
    solutionForCoupels_p: "Your wedding, engagement, anniversary, or any significant celebration holds a treasure trove of emotions, love, and beautiful memories. It is a symphony of love and emotions, and we are here to compose it into a visual masterpiece. Our passion lies in encapsulating those fleeting moments of pure happiness, transforming them into timeless treasures you can hold close to your heart forever.We believe in the power of storytelling through the lens, creating a visual narrative that beautifully portrays the emotions, laughter, tears, and the countless unspoken emotions that unfold during your day. Our goal is to not just capture photographs but to craft a story that resonates with the depth of your emotions. ",
    solutionForCoupels_p2: "With an artful eye for detail and a keen sense of spontaneity, our team of dedicated photographers and videographers work seamlessly to encapsulate every smile, every glance, and every shared moment. We blend traditional elegance with contemporary flair, ensuring that every click immortalizes the essence of your celebration. Trust us to be there, quietly observing, and diligently capturing every fleeting moment, every stolen glance, and every loving embrace.",
    about_p: "At The Bliss Events, we don’t just capture moments; we create timeless memories that will etch a smile on your face and a flutter in your heart every time you look back. From the pre-wedding shoot to the ceremony and beyond, our photography services are comprehensive. We offer a range of packages tailored to suit your preferences, ensuring that every detail is covered, leaving you free to immerse yourself fully in the joy of your celebration.",
    aboutPointArray: [
        "Pre-wedding shoot ",
        "Artistic Direction and Posing Guidance",
        "Engagement and Post-Wedding Sessions",
        "Online Gallery Creation",
        "Photo Booths",
    ],
    getFromThisService: "Our photography services are not just about capturing images; they're about crafting a visual narrative that tells the unique story of your love. We are committed to providing an experience that exceeds your expectations, offering services that preserve the authenticity, emotions, and beauty of your special day in every frame captured.",
    getFromThisService_Ponints: [
        "Personalized Slideshows and Montages: Crafting captivating slideshows or montages of your photographs and videos, set to music, to relive the magical moments of your wedding day.",
        "Exquisite Album Design: Crafting meticulously designed albums that showcase the narrative of your day, ensuring each page reflects the emotions and highlights of your celebration.",
        "Family and Group Portraits: Organizing and capturing formal family portraits and group shots, ensuring that your entire circle of loved ones is beautifully documented on your special day.",
        "Photography Workshops and Tips: Providing guidance and tips for couples who wish to capture their own moments, offering workshops or consultations to help you take stunning photographs during your wedding journey.",
        "Post-Event Support: Ensuring continued assistance even after your event, helping you with photo selection, album design, and any additional requests you may have regarding your photographs.",
    ]
}

const PhotograpgyPage = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Photography Page" });
        window.scroll(0, 0)
    }, [])
    return (
        <section>
            <HeaderSection title={page.title} />
            <div className="flex justify-evenly items-start lg:flex-row flex-col-reverse max-w-[1600px] mx-auto">
                <Categories />
                <ContentPage {...page} />
            </div>
            <OurServices />
        </section>
    )
}

export default PhotograpgyPage