import React from 'react'
import { MdOutlinePhone } from 'react-icons/md'
import { CiLocationOn, CiFacebook, CiTwitter, CiLinkedin } from 'react-icons/ci'
import { BiLogoInstagram, BiLogoYoutube } from 'react-icons/bi'

const RedHeader = () => {
    return (
        <div className='w-full hidden md:block bg-[#B40101 bg-white md:px-5 py-1'>
            <div className='flex justify-center md:justify-between flex-col md:flex-row gap-2 items-center'>
                <div className='flex  md:flex-row gap-x-8 items-center'>
                    <a href='tel:+917021927602' className='flex items-center gap-x-2 text-[#B40101] text-sm font-semibold'>
                        <MdOutlinePhone size={25} className='text-[#B40101] rounded-full' />
                        +91 70219 27602
                    </a>
                    <a href="https://maps.app.goo.gl/PLT5THsvTzhEDXYj6" target="_blank" rel="noopener noreferrer" className='flex items-center gap-x-2 text-[#B40101] text-sm font-semibold'>
                        <CiLocationOn size={25} className=' text-[#B40101]  rounded-full' />
                        Nagpur
                    </a>
                </div>
                <div className='hidden md:flex gap-x-4 gap-y-2 items-center flex-wrap'>
                    <a href="https://www.youtube.com/channel/UC3XPvmZv64S_7BeJAtLZeuA" target="_blank" rel="noopener noreferrer">
                        <BiLogoYoutube size={25} className=' text-[#B40101]  rounded-full' />
                    </a>
                    <a href="https://www.facebook.com/theblisseventsofficial/" target="_blank" rel="noopener noreferrer">
                        <CiFacebook size={25} className='text-[#B40101]  rounded-full' />
                    </a>
                    <a href="https://www.linkedin.com/in/the-bliss-event-1841b91b9/" target="_blank" rel="noopener noreferrer">
                        <CiLinkedin size={25} className=' text-[#B40101]  rounded-full' />
                    </a>
                    <a href="https://www.instagram.com/blisseventsofficial" target="_blank" rel="noopener noreferrer">
                        <BiLogoInstagram size={25} className=' text-[#B40101] rounded-full' />
                    </a>
                </div>
            </div>

        </div>
    )
}

export default RedHeader