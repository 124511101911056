import React, { useEffect, useRef, useState } from "react";
import {
    Navbar,
    Typography,
    Button,
    IconButton,
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
} from "@material-tailwind/react";
import { BsChevronDown } from 'react-icons/bs'
import { FaBars } from 'react-icons/fa'
import { RxCross2 } from 'react-icons/rx'
import { Link } from "react-router-dom";

import roundLogo from '../../Assets/logo/The_Bliss_Event_Logo.svg'
import textLogo from '../../Assets/logo/The_Bliss_Event_Text_Logo_black.svg'
import redElipce from '../../Assets/Navbar/RedElipice.svg';
import newLogo from '../../Assets/logo/The Bliss Events (2).png'

export const navListItem = [
    {
        title: "Home",
        linkTo: "/",
        items: false
    },
    {
        title: "Service",
        linkTo: "/services",
        // items: [
        //     {
        //         title: "item1",
        //         linkTo: "/"
        //     },
        //     {
        //         title: "item2",
        //         linkTo: "/"
        //     },
        //     {
        //         title: "item3",
        //         linkTo: "/"
        //     },
        // ]
    },
    // {
    //     title: "Planning Tools",
    //     linkTo: "/",
    //     items: [
    //         {
    //             title: "item21",
    //             linkTo: "/"
    //         },
    //         {
    //             title: "item12",
    //             linkTo: "/"
    //         },
    //         {
    //             title: "item13",
    //             linkTo: "/"
    //         },
    //     ]
    // },
    {
        title: "Blogs",
        linkTo: "/blogs",
        items: false
    },
    {
        title: "Contact Us",
        linkTo: "/contact-us",
        items: false
    },
    {
        title: "About Us",
        linkTo: "/about-us",
        items: false
    },
    {
        title: "More",
        linkTo: "/",
        items: [
            {
                title: "Testimonial",
                linkTo: "/testimonial"
            },
            {
                title: "Privacy Policy",
                linkTo: "/privacy-policy"
            },
            {
                title: "Team",
                linkTo: "/team"
            },
            {
                title: "Term of Services",
                linkTo: "/term-of-services"
            },
            {
                title: "FAQ",
                linkTo: "/faq"
            },
        ]
    },
]

const NavBar = () => {
    const [openNav, setOpenNav] = React.useState(false);
    const [menuPostion, setMenuPostion] = useState(false)
    const svgEle = useRef()

    React.useEffect(() => {
        window.addEventListener(
            "resize",
            () => window.innerWidth >= 1250 && setOpenNav(false),
        );
    }, []);
    React.useEffect(() => {
        window.addEventListener(
            "resize",
            () => {
                if (window.innerWidth <= 720) {
                    setMenuPostion(false)
                } else if (window.innerWidth >= 720 && window.innerWidth <= 1250) {
                    setMenuPostion(true)
                }
                else if (window.innerWidth >= 1250) {
                    setMenuPostion(false)
                }

            }

        );
    }, []);

    useEffect(() => {
        if (!openNav) {
            svgEle.current?.classList.add("rotate-180")
        } else {
            svgEle.current?.classList.remove("rotate-180")
        }
    }, [openNav])

    const navList = (
        <ul className="mt-2 mb-4 grid grid-cols-2 xl:flex gap-2 xl:mb-0 xl:mt-0 xl:flex-row xl:justify-between xl:items-center xl:gap-6 text-xl !hover:border-none !border-none  outline-none">
            {
                navListItem?.map((ele) => <Typography
                    key={ele.title}
                    as="li"
                    className="p-1 font-normal "
                >
                    {
                        ele.items ?
                            (
                                <Menu
                                    allowHover
                                    placement={menuPostion ? "left" : "bottom"}
                                >
                                    <MenuHandler>
                                        <Typography
                                            variant="paragraph"
                                            className="flex items-center text-lg md:text-white font-semibold cursor-pointer lg:no-underline underline underline-offset-4"
                                        >
                                            {ele.title}
                                            &nbsp;
                                            <BsChevronDown
                                                className={`h-3.5 w-3.5 transition-transform `}
                                            />
                                        </Typography>
                                    </MenuHandler>
                                    <MenuList className="mt-6 hover:outline-none" >
                                        {
                                            ele.items?.map((eleItem) => <Link key={eleItem.title} to={eleItem.linkTo} >
                                                <MenuItem className=" hover:outline-none">{eleItem.title}</MenuItem>
                                            </Link>

                                            )
                                        }
                                    </MenuList>
                                </Menu>
                            )
                            : (

                                <Link to={ele.linkTo} className="flex items-center text-lg md:text-white  font-semibold lg:no-underline underline underline-offset-4">
                                    {ele.title}
                                </Link>
                            )
                    }

                </Typography>)
            }


        </ul >
    );

    return (
        <div className="sticky top-0 left-0 z-[999] w-full bg-[#B40101] ">
            <Navbar className=" h-20 max-w-[1600px] mx-auto rounded-none px-4 py-2 xl:px-8 xl:py-0 bg-inherit border-none backdrop-blur-none ">

                <div className="flex items-center justify-between text-blue-gray-900 max-w-[1500px] h-full mx-auto">
                    <div className="flex  items-center">
                        <Link
                            to="/"
                            className="mr-4  cursor-pointer p-1.5 font-medium "
                        >
                            <img src={newLogo} alt="The_Bliss_Event_Logo" className="max-h-14 xl:max-h-16" />

                        </Link>
                        {/* <Link
                            to="/"
                            className="mr-4  cursor-pointer py-1.5 font-medium "
                        >
                            <img src={roundLogo} alt="The_Bliss_Event_Logo" className="max-h-14 xl:max-h-16" />

                        </Link>
                        <Link
                            to="/"
                            className="mr-4 h-14 cursor-pointer py-1.5 font-medium block"
                        >

                            <img src={textLogo} alt="The_Bliss_Event_Text_Logo" className="max-h-14" />
                        </Link> */}
                    </div>
                    <div className="self-center flex items-center xl:justify-between gap-4 xl:w-[70%]">
                        <div className="mr-4 hidden xl:block ">
                            {navList}
                        </div>
                        <div className="hidden md:flex items-center gap-x-1 ">
                            <Button
                                variant="filled"
                                className=" inline-block bg-[#fff] text-[#B40101] shadow shadow-[#B40101] font-semibold"
                            >
                                <Link to={"/book-appointment"}>Book Now</Link>
                            </Button>
                        </div>
                        <Menu open={openNav} handler={setOpenNav} className="xl:hidden " offset={20}>
                            <MenuHandler>
                                <IconButton
                                    ref={svgEle}
                                    variant="text"
                                    className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent xl:hidden relative flex justify-center items-center transition-transform ease-linear duration-200"
                                    ripple={false}
                                >
                                    {openNav ?
                                        <RxCross2
                                            size={30}
                                            onClick={() => setOpenNav(!openNav)}
                                            className=" transition-transform ease-linear duration-200 text-[#f5f8b6]"
                                        />
                                        :
                                        <FaBars
                                            size={30}
                                            className="text-[#f5f8b6]"
                                            onClick={() => setOpenNav(!openNav)} />
                                    }
                                </IconButton>
                            </MenuHandler>
                            <MenuList className="xl:hidden w-full md:w-1/2 border-none">
                                {navList}
                            </MenuList>
                        </Menu>

                    </div>
                </div>

            </Navbar >
        </div >
    );
}

export default NavBar;



