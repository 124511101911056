import React from 'react'
import HeaderSection from '../Components/HeaderSection/HeaderSection'
import HeroSwiper from '../Components/Wedding/HeroSwiper'

import Rajsthani from '../Assets/TraditionalWedding/rajstani.svg'
import South from '../Assets/TraditionalWedding/south.svg'
import Malvi from '../Assets/TraditionalWedding/malvi.svg'
import Gujati from '../Assets/TraditionalWedding/gujati.svg'
import Kerla from '../Assets/TraditionalWedding/kerla-wedding.svg'

import Img1 from '../Assets/TraditionalWedding/traditional1.svg'
import Img2 from '../Assets/TraditionalWedding/traditional2.svg'
import Img3 from '../Assets/TraditionalWedding/traditional3.svg'
import Img4 from '../Assets/TraditionalWedding/traditional4.svg'

import Card1 from '../Assets/TraditionalWedding/traditional-card1.svg'
import Card2 from '../Assets/TraditionalWedding/traditional-card2.svg'
import Card3 from '../Assets/TraditionalWedding/traditional-card3.svg'
import Card4 from '../Assets/TraditionalWedding/traditional-card4.svg'
import Card5 from '../Assets/TraditionalWedding/traditional-card5.svg'
import Card6 from '../Assets/TraditionalWedding/traditional-card6.svg'

import StoriesCard1 from '../Assets/TraditionalWedding/traditional-stories1.svg'
import StoriesCard2 from '../Assets/TraditionalWedding/traditional-stories2.svg'
import StoriesCard3 from '../Assets/TraditionalWedding/traditional-stories3.svg'
import StoriesCard4 from '../Assets/TraditionalWedding/traditional-stories4.svg'
import StoriesCard5 from '../Assets/TraditionalWedding/traditional-stories5.svg'
import SmallSwiper from '../Components/Wedding/SmallSwiper'
import OurWeddingStories from '../Components/Wedding/OurWeddingStories'
import Categories from '../Components/LandingPage/Categories/Categories'

const swiperArray = [
    {
        img: Rajsthani,
        alt: "Rajsthani-Wedding",
    },
    {
        img: South,
        alt: "South-Wedding",
    },
    {
        img: Kerla,
        alt: "Kerla-Wedding",
    },
    {
        img: Malvi,
        alt: "Malvi-Wedding",
    },
    {
        img: Gujati,
        alt: "Gujrati-Wedding",
    },
]

const page = {
    title1: "Top Best Places for your Dream Wedding",
    detailsArray1: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    ],
    title2: "Complete Guide for destination wedding",
    detailsArray2: [
        "Voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro  quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, ",
    ],
    smallSwiperArray: [
        {
            img: Img1,
            alt: "Tranditional-Wedding1",
        },
        {
            img: Img2,
            alt: "Tranditional-Wedding1",
        },
        {
            img: Img3,
            alt: "Tranditional-Wedding1",
        },
        {
            img: Img4,
            alt: "Tranditional-Wedding1",
        },

    ],
    servicesList: [
        { title: "", img: Card1 },
        { title: "", img: Card2 },
        { title: "", img: Card3 },
        { title: "", img: Card4 },
        { title: "", img: Card5 },
        { title: "", img: Card6 },

    ],
    about_p: "Voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatiset quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur autodit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porroquisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.",
    getFromThisService: "We orchestrate moments of joy and merriment, infusing the atmosphere with lively music, traditional performances, and engaging activities that bring everyone closer together in celebration.",
    getFromThisService_Ponints: [
        "Timing and Coordination: Planning the schedule for the Haldi ceremony, including timing for the arrival of family and friends, ritual performances, and any cultural customs associated with the ceremony.",
        "Guest Comfort and Hospitality: Providing assistance to guests by ensuring their comfort, offering refreshments, and overseeing any special requirements or accommodations.",
        "Entertainment and Music: Organizing traditional music or performances to add cultural vibrancy and joy to the ceremony.",
        "Assistance for the Couple: Offering support and assistance to the bride and groom, ensuring they are comfortable and have everything they need for the ceremony.",
        "Clean-Up and Post-Event Management: Managing the clean-up after the ceremony, ensuring that the venue is left in its original state and handling any necessary follow-ups or arrangements.",
    ],
    weddingServicesCard: [
        {
            name: "Mahesh & Madhavi",
            city: "Jodhpu",
            img: StoriesCard1
        },
        {
            name: "Jack & Rose",
            city: "Agra",
            img: StoriesCard2
        }, {
            name: "Rakesh & Mamya",
            city: "Udaipur",
            img: StoriesCard3
        }, {
            name: "Nick & Hazel",
            city: "Jaipur",
            img: StoriesCard4
        }, {
            name: "Ash & Ember",
            city: "Goa",
            img: StoriesCard5
        }
    ]
}

const TraditionalWedding = () => {
    return (
        <div>
            <HeaderSection title={"Traditional Wedding"} />
            <HeroSwiper {...page} swiperArray={swiperArray} />
            <SmallSwiper {...page} />
            <OurWeddingStories {...page} />
            <Categories />
        </div>
    )
}

export default TraditionalWedding