import client1 from './Assets/People/client/client-1.svg'
import client2 from './Assets/People/client/client-2.svg'
import client3 from './Assets/People/client/client-3.svg'
import client4 from './Assets/People/client/client-4.svg'
import client5 from './Assets/People/client/client-5.svg'
import client6 from './Assets/People/client/client-6.svg'

import Damini_Kalekar from './Assets/People/client/damini-kalekar.svg'
import ARYA_YADAV from './Assets/People/client/ARYA-YADAV.svg'
import AHANA_MALIK from './Assets/People/client/AHANA-MALIK.svg'
import HARSH_MUNDLE from './Assets/People/client/HARSH-MUNDLE.svg'
import AYUSH_RAMTEKE from './Assets/People/client/AYUSH-RAMTEKE.svg'
import SAIF_SHEIKH from './Assets/People/client/SAIF-SHEIKH.svg'



export const cleientFeedbackArray = [
    {
        img: Damini_Kalekar,
        name: "DAMINI KALEKAR",
        // designation: "Director, Novatech",
        feedback: "The Bliss Events made our day perfect! Their creativity, attention to detail, and professionalism exceeded expectations. Stress-free and enjoyable, highly recommend for a magical wedding experience.",
    },
    {
        img: ARYA_YADAV,
        name: "ARYA YADAV",
        // designation: "Engineer, Persistent",
        feedback: "Our heartfelt thanks to Is Bliss Events for an incredible wedding experience. Professionalism, creativity, and seamless execution. They made our day magical. Highly recommended for a perfect celebration.",
    },
    {
        img: AHANA_MALIK,
        name: "AHANA MALIK",
        // designation: "Engineer, Nagpur",
        feedback: "Unforgettable! Bliss Events have surpassed all expectations, turning our vision into a magical reality. Professional, creative, and stress-free. Highly recommend for a perfect wedding day.",
    },
    {
        img: HARSH_MUNDLE,
        name: "HARSH MUNDLE",
        // designation: "Enterpreneur",
        feedback: "Gratitude to Bliss Events for an exceptional wedding. Professionalism, creativity, and genuine care exceeded expectations. Our day was perfect, stress-free, and filled with beautiful memories. Highly recommended!",
    },
    {
        img: AYUSH_RAMTEKE,
        name: "AYUSH RAMTEKE",
        // designation: "MD, ITec",
        feedback: "The Bliss Events orchestrated our dream wedding flawlessly. Their attention to detail and genuine care made our day stress-free and memorable. A top-notch team we highly recommend.",
    },
    {
        img: SAIF_SHEIKH,
        name: "SAIF SHAIKH",
        // designation: "Enterprenaur",
        feedback: "Heartfelt thanks to Is Bliss Events for a perfect wedding. Their attention to detail, creativity, and genuine care made our day unforgettable. Highly recommend for a seamless and joyous celebration.",
    },
]