import { Typography } from "@material-tailwind/react";
import React from "react";
import { FaFacebookF, FaTwitter, FaInstagram, FaMediumM } from 'react-icons/fa'
import { FaLocationDot, FaSquarePinterest } from 'react-icons/fa6'
import { MdOutlineMail } from 'react-icons/md'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { FiChevronRight } from 'react-icons/fi'
import { FaYoutube } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { IoLocation } from "react-icons/io5";


import textLogo from '../../Assets/logo/New_The_Bliss_Events_Text_Logo.svg';
import { Link } from "react-router-dom";
import { link } from "@uiw/react-md-editor";

const LINKS = [
    {
        title: "Service Links",
        items: [
            {
                title: "Home",
                linkTo: "/"
            }
            ,
            {
                title: "Services",
                linkTo: "/services"
            },
            {
                title: "About Us",
                linkTo: "/about-us"
            },
            {
                title: "Testimonials",
                linkTo: "/testimonial"
            },
            {
                title: "Our Team",
                linkTo: "/team"
            }
        ],
    },
    {
        title: "Important Links",
        items: [
            {
                title: "Our Products",
                linkTo: "/"
            },
            {
                title: "Contact",
                linkTo: "/contact-us"
            },
            {
                title: "Appointment",
                linkTo: "/book-appointment"
            },
            {
                title: "Terms & Conditions",
                linkTo: "/term-of-services"
            },
            {
                title: "FAQ",
                linkTo: "/faq"
            }
        ],
    },
];

const SOCAILMEDIALINKS = [
    {
        icon: <FaFacebookF size={18} className="text-[#B40101] transition-all duration-300 ease-linear hover:scale-150" />,
        link: "https://www.facebook.com/theblisseventsofficial/",
    },
    {
        icon: <FaYoutube size={18} className="text-[#B40101] transition-all duration-300 ease-linear hover:scale-150" />,
        link: "https://www.youtube.com/channel/UC3XPvmZv64S_7BeJAtLZeuA",
    },
    {
        icon: <FaInstagram size={18} className="text-[#B40101] transition-all duration-300 ease-linear hover:scale-150" />,
        link: "https://www.instagram.com/blisseventsofficial",
    },
    {
        icon: <FaSquarePinterest size={18} className="text-[#B40101] transition-all duration-300 ease-linear hover:scale-150" />,
        link: "https://www.pinterest.jp/theblissevents1985/",
    },
    {
        icon: <FaMediumM size={18} className="text-[#B40101] transition-all duration-300 ease-linear hover:scale-150" />,
        link: "https://medium.com/@theblissevents1985",
    },
]

const currentYear = new Date().getFullYear();

const NewFooter = () => {
    return (
        <footer className="md:mt-16 mt-12">
            <div className="relative flex justify-evenly flex-wrap gap-y-2 bg-[#9A0016] text-white py-6 md:w-10/12 w-full mx-auto z-50">
                <li className="flex items-start gap-x-2 mx-auto xl:text-sm " >
                    <BsFillTelephoneFill className="text-white mt-1 lg:text-lg" />
                    <div>
                        <p className=" font-['Lexend] font-medium lg:text-xl text-sm mb-1">Give Us A Call</p>
                        <a
                            href="tel:+917021927602"
                            target="_blank"
                            rel="noopene     Locationr noreferrer"
                            className="pb-1.5 font-normal transition-colors "
                        >
                            <p className="lg:text-[15px] text-xs text-[#ffffff]/90 font-['Open_Sans']">
                                +917021927602
                            </p>
                        </a>
                    </div>
                </li>
                <li className="flex items-start gap-x-2 mx-auto xl:text-sm " >
                    <IoMail className="text-white lg:mt-1 mt-[2px] lg:text-xl" />
                    <div>
                        <p className=" font-['Lexend] font-medium lg:text-xl text-sm mb-1">Send Us A Message</p>
                        <a
                            href="mailto:theblissevents1985@gmail.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="pb-1.5 font-normal transition-colors lowercase"
                        >
                            <p className="lg:text-[15px] text-xs text-[#ffffff]/90 
                            font-['Open_Sans']">
                                theblissevents1985@gmail.IN
                            </p>
                        </a>
                    </div>
                </li>
                <li className="flex items-start gap-x-2 mx-auto xl:text-sm " >
                    <IoLocation className="text-white mt-1 lg:text-xl" />
                    <div>
                        <p className=" font-['Lexend] font-medium lg:text-xl text-sm mb-1">Office Location</p>
                        <a
                            href="https://maps.app.goo.gl/PLT5THsvTzhEDXYj6"
                            target="_blank"
                            rel="noopener noreferrer"
                            className=""
                        >
                            <p className="lg:text-[15px] text-xs text-[#ffffff]/90 font-['Open_Sans']">
                                Nagpur
                            </p>
                        </a>
                    </div>
                </li>

            </div>
            <div className=" w-full bg-[#B40101] text-white lg:pt-12 pt-10 -mt-12">
                <div className="max-w-[1600px] mx-auto w-11/12 md:w-10/12 lg:w-11/12 xl:w-10/12">

                    <div className="md:grid md:grid-cols-1 md:justify-between gap-6 lg:grid-cols-6 flex flex-wrap justify-start items-start  py-20">
                        <div className="col-span-2 ">
                            <div className=" flex gap-x-4 mb-[22px] justify-center items-center md:justify-start !-mt-5">
                                <div className="w-16 h-16 rounded-full flex justify-center items-center bg-white">
                                    <img src="logo-512x512.png" alt="the-bliss-event-logo" className="w-11" />
                                </div>
                                <img src={textLogo} alt="the bliss events logo" className="xl:w-9/12 lg:w-8/12 w-8/12" />
                            </div>
                            <div>
                                <h4 className=" text-sm text-[#ffffff]/90 font-['Open_Sans']">
                                    At The Bliss Events our strength lies in our belief in the need to incorporate the creative impulse in your events and give them an ever-lasting effect.
                                </h4>
                            </div>
                            <div>
                                <p className="flex items-center gap-x-4 mt-5">
                                    {
                                        SOCAILMEDIALINKS?.map((ele, i) =>
                                            <a
                                                key={i}
                                                href={ele.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="bg-white p-[12px] rounded-full">
                                                {ele.icon}
                                            </a>

                                        )
                                    }

                                </p>
                            </div>
                        </div>
                        <div className="col-span-4 grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3  justify-center items-start md:justify-between gap-4">
                            {LINKS.map(({ title, items }) => (
                                <ul key={title} className="w-full  mx-auto">
                                    <Typography
                                        variant="paragraph"

                                        className="mb-2 font-medium font-['Lexend'] xl:text-2xl lg:text-lg text-base self-start"
                                    >
                                        {title}
                                        <span className="relative w-8/12">
                                            <span className="h-[2px] bg-white opacity-50">
                                            </span>
                                            <span className="h-[2px] bg-white w-1/2 absolute top-0">
                                            </span>

                                        </span>

                                    </Typography>
                                    <div className=" w-10 h-[2px] bg-white mb-5"></div>

                                    {items.map((link) => (
                                        <li key={link.title}>
                                            <Link
                                                to={link.linkTo}

                                                className="py-1.5 text-[#ffffff]/90 font-['Open_Sans'] font-normal transition-colors md:text-sm text-xs flex gap-x-1 items-center relative"
                                            >
                                                {link.title}

                                            </Link >
                                        </li>
                                    ))}
                                </ul>
                            ))}
                            <ul className=" col-span-2 md:col-span-1 mx-auto w-full">
                                <Typography
                                    variant="paragraph"
                                    className="mb-2 font-medium font-['Lexend'] xl:text-2xl lg:text-lg text-base  "
                                >
                                    Work Hours
                                    <span className="relative w-8/12">
                                        <span className="h-[2px] bg-white opacity-50">
                                        </span>
                                        <span className="h-[2px] bg-white w-1/2 absolute top-0">
                                        </span>

                                    </span>

                                </Typography>
                                <div className=" w-10 h-[2px] bg-white mb-5"></div>
                                <li className="xl:text-base lg:text-sm flex items-start gap-x-2 mx-auto font-bold mt-[14px] font-['Open_Sans']" >
                                    8 AM - 5.PM, Monday - Saturday
                                </li>
                                {/* <li className="flex items-start gap-x-2 mx-auto font-medium md:mt-5 mt-3 text-[#ffffff]/90 font-['Open_Sans'] md:text-sm text-xs" >
                                    Lorem ipsum dolor sit amet consec teturadipiscing elit eiusmod tempor incidunt
                                </li> */}
                                <li className="flex items-start gap-x-2 mx-auto font-medium mt-5 " >
                                    <a
                                        href="tel:+917021927602"
                                        rel="noopener noreferrer"
                                        className="w-36 bg-white py-2 rounded-lg"
                                    >
                                        <p className=" text-center font-extrabold text-lg text-[#B40101] font-['Open_Sans']">
                                            Call Us Today
                                        </p>

                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div className="md:h-14 h-12 flex items-center justify-center text-[#ffffff]/90 font-['Open_Sans'] md:text-[15px] text-xs border-t border-white/90">
                    <div className=" lg:w-10/12 w-11/12 mx-auto flex md:justify-between flex-wrap text-[#ffffff]/90 font-['Open_Sans'] md:text-[15px] text-xs ">
                        <p>&copy;2024 The Bliss Events. All Right Reserved.</p>
                        <p>Designed & Developed by GBJ Buzz Pvt. Ltd</p>

                    </div>
                </div>

            </div>
        </footer>
    );
}


export default NewFooter;

