import React, { useState } from 'react'
import { Typography } from '@material-tailwind/react'
import InputField from '../InputField/InputField'
import { BsHeartFill, BsPlayCircleFill } from 'react-icons/bs'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from 'swiper/modules';

import "swiper/css";
import 'swiper/css/effect-fade';

import stack from '../../../Assets/LandingPage/DreamWedCards/stack.svg'
import rainCloud from '../../../Assets/LandingPage/DreamWedCards/rainCloud.svg'
import fork from '../../../Assets/LandingPage/DreamWedCards/fork&knife.svg'
import vendor from '../../../Assets/LandingPage/DreamWedCards/Vendor.svg'

import MarrigaeImg from '../../../Assets/LandingPage/dream-marriage-img.webp'
import DreamWed2 from '../../../Assets/LandingPage/DreamWedCards/dream-wed-2.webp'
import DreamWed3 from '../../../Assets/LandingPage/DreamWedCards/dream-wed-3.webp'

const dreamWedCardArray = [
    {
        img: vendor,
        title: "Vendor & Contact Management",
    },
    {
        img: fork,
        title: "Venue and Food Plans",
    },
    {
        img: rainCloud,
        title: "On-site management",
    },
    {
        img: stack,
        title: "Time Management",
    },
]

const swiperArray = [
    MarrigaeImg, DreamWed2, DreamWed3
]


const DreamMarriage = () => {
    const [search, setSearch] = useState('')


    return (
        <section className='max-w-[1600px] mx-auto relative'>
            <div className='w-11/12 mx-auto lg:w-10/12'>
                <div className='grid grid-cols-1 md:grid-cols-2 justify-between items-center  my-20'>
                    <div className='relative'>
                        <div
                            data-aos="fade-right"
                            data-aos-duration="1000"
                            data-aos-delay="100"
                            className='flex flex-col gap-y-3 border-l-8 border-[#B40101] pl-5'>
                            <Typography variant='h5' className='text-[#49516F] font-normal text-xl lg:text-2xl' >
                                Call us today to make your
                            </Typography>
                            <Typography variant='h5' className='text-[#B40101] font-bold text-xl lg:text-3xl ' >
                                Dream wedding comes true
                            </Typography>
                            <Typography className='text-[#49516F] font-normal w-8/12 text-xs lg:text-base' >
                                Discover the beauty of a dream realized, only with The Bliss Events
                            </Typography>
                        </div>
                        <div className='grid grid-cols-2 my-16 gap-y-10 gap-x-2'>


                            {
                                dreamWedCardArray?.map((ele, i) => <DreamWedCard key={i} {...ele} />)
                            }
                        </div>
                    </div>
                    <div className='flex justify-center items-center' >
                        <Swiper
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            effect={'fade'}
                            modules={[Autoplay, EffectFade]}
                            className='mySwiper'
                        >
                            {
                                swiperArray?.map((ele, i) => <SwiperSlide key={i}>
                                    <img src={ele} alt={`DreamWeeding ${i}`} />
                                </SwiperSlide>)
                            }


                        </Swiper>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default DreamMarriage


const DreamWedCard = ({ img, title }) => <div className='flex md:gap-4 gap-[6px] items-center'>
    <img src={img} alt={title} loading='lazy' />
    <Typography className='text-[#49516F] font-semibold lg:text-base md:text-sm text-xs' >
        {title}
    </Typography>
</div>


