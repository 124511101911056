import React from 'react'
import { Routes, Route } from 'react-router-dom'
import LandingPage from '../Pages/LandingPage'
import Footer from '../Components/Footer/Footer'
import NavBar from '../Components/NavBar/NavBar'
import ContactUsPage from '../Pages/ContactUsPage'
import BlogPage from '../Pages/BlogPage'
import ServicesPage from '../Pages/ServicesPage'
import RedHeader from '../Components/NavBar/RedHeader'
import TestimonialPage from '../Pages/TestimonialPage'
import PrivicyPolicy from '../Pages/PrivicyPolicy'
import TermsOfServices from '../Pages/TermsOfServices'
import TeamPage from '../Pages/TeamPage'
import BookAppointment from '../Pages/BookAppointment'
import FaqPage from '../Pages/FaqPage'
import AboutUsPage from '../Pages/AboutUsPage'
import AddBlogPage from '../Pages/AddBlogPage'
import BlogDetailPage from '../Pages/BlogDetailPage'
import ReceptionPage from '../Pages/ReceptionPage'
import PhotograpgyPage from '../Pages/PhotographyPage'
import HaldiPage from '../Pages/HaldiPage'
import DecorationPage from '../Pages/DecorationPage'
import MakeUpPage from '../Pages/MakeUpPage'
import CaterersPage from '../Pages/CaterersPage'
import DestinationWedding from '../Pages/DestinationWedding'
import RoyalWedding from '../Pages/RoyalWedding'
import TraditionalWedding from '../Pages/TraditionalWedding'
import NewFooter from '../Components/Footer/NewFooter'


const MainRoutes = () => {
  return (
    <>
      <RedHeader />
      <NavBar />
      <Routes>
        <Route path={'/'} element={<LandingPage />} />
        <Route path={'/contact-us'} element={<ContactUsPage />} />
        <Route path={'/blogs'} element={<BlogPage />} />
        <Route path={'/services'} element={<ServicesPage />} />
        <Route path={'/testimonial'} element={<TestimonialPage />} />
        <Route path={'/privacy-policy'} element={<PrivicyPolicy />} />
        <Route path={'/term-of-services'} element={<TermsOfServices />} />
        <Route path={'/team'} element={<TeamPage />} />
        <Route path={'/book-appointment'} element={<BookAppointment />} />
        <Route path={'/faq'} element={<FaqPage />} />
        <Route path={'/about-us'} element={<AboutUsPage />} />
        <Route path={'/add-blog'} element={<AddBlogPage />} />
        <Route path={'/blog/:id'} element={<BlogDetailPage />} />
        <Route path={'/service/reception'} element={<ReceptionPage />} />
        <Route path={'/service/photography'} element={<PhotograpgyPage />} />
        <Route path={'/service/haldi'} element={<HaldiPage />} />
        <Route path={'/service/bridal-makeup'} element={<MakeUpPage />} />
        <Route path={'/service/caterers'} element={<CaterersPage />} />
        <Route path={'/service/decoration'} element={<DecorationPage />} />
        <Route path={'/destination-wedding'} element={<DestinationWedding/>} />
        <Route path={'/royal-wedding'} element={<RoyalWedding/>} />
        <Route path={'/traditional-wedding'} element={<TraditionalWedding/>} />

      </Routes>
      <NewFooter/>
    </>
  )
}

export default MainRoutes;