import React from 'react';
import { IoAirplaneOutline, IoAirplane, IoHeart, IoHeartOutline } from "react-icons/io5";



const OurWeddingStories = ({ weddingServicesCard, flight }) => {
    return (
        <section className='w-11/12 xl:w-10/12 lg:w-11/12 mx-auto mt-4  '>
            <div className='lg:w-8/12 w-full mx-auto group flex flex-wrap md:gap-x-2 gap-x-1 items-center justify-center transition-all duration-700 ease-linear'>
                {/* <img src={flightImg} alt="flight-icon" className='' />     */}{
                    flight &&
                    <>
                        <IoAirplaneOutline className=' group-hover:hidden transition-all duration-700 ease-linear lg:text-8xl text-6xl -rotate-[32deg] text-[#B40101] mr-3 ' />
                        <IoAirplane className='hidden group-hover:block transition-all duration-700 ease-linear lg:text-8xl text-6xl  -rotate-[32deg] text-[#B40101] mr-3' />

                    </>
                }
                <h2 className=' lg:text-4xl text-2xl font-semibold'>Our Wedding </h2>
                <IoHeartOutline className=' group-hover:hidden transition-all duration-700 ease-linear lg:text-6xl text-4xl text-[#B40101] ' />
                <IoHeart className='hidden group-hover:block transition-all duration-700 ease-linear lg:text-6xl text-4xl text-[#B40101] ' />
                <h2 className='lg:text-4xl text-2xl  font-semibold text-[#B40101]'>Stories</h2>

            </div>


            <div className=' xl:grid grid-cols-5 xl:gap-5 flex xl:justify-between justify-center  md:gap-10 gap-5 py-12 xl:flex-nowrap flex-wrap '>
                {
                    weddingServicesCard?.map((el, i) => (
                        <div className={`relative xl:w-full md:w-[13.5rem] ${i===4 ? "w-48":"w-[44%]"} flex items-center justify-center text-white group`} key={i}>
                            <img src={el.img} alt={el.title} className={`w-full md:h-72 ${i===4?"h-60":"h-48"} object-cover group-hover:scale-105  transition-all duration-300 ease-linear`} />
                            <div className='w-full py-3 absolute -bottom-1 bg-black/70 opacity-0 group-hover:opacity-100 group-hover:scale-105  transition-all duration-300 ease-linear text-center z-50 shadow-md '>
                                <p className=' md:text-xs text-[11px]'>{el.name}</p>
                                <p className='md:text-base text-sm font-semibold '>{el.city}</p>
                            </div>

                        </div>
                    ))
                }
            </div>
        </section>
    )
}

export default OurWeddingStories
