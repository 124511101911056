import React, { useEffect } from 'react'
import HeaderSection from '../Components/HeaderSection/HeaderSection';
import ContactForm from '../Components/ContactUs/ContactForm/ContactForm';
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GA_ID);

const ContactUsPage = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Contact us page" });

    window.scroll(0, 0)
  }, [])
  return (
    <>
      <HeaderSection title={"Contact Us"} description={"Any question or remarks? Just write us a message!"} />
      <ContactForm />
    </>
  )
}

export default ContactUsPage;
