import React from 'react'
import { Swiper, SwiperSlide, } from 'swiper/react'
import { Autoplay, EffectFade } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/effect-fade';




const HeroSwiper = ({ swiperArray, title1, detailsArray1 }) => {
    return (
        <section className='max-w-[1600px] mx-auto w-11/12 xl:w-10/12 mt-8'>
            <h2 className='text-[#051145] font-semibold md:text-3xl text-xl font-["Lexend"] text-center lg:hidden mb-8'>
                {title1}
            </h2>
            <div>
                <Swiper
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, EffectFade]}
                    effect={'fade'}
                    className='mySwiper'
                >
                    {
                        swiperArray?.map((ele, i) => <SwiperSlide key={i}>
                            <img src={ele.img} alt={ele.alt} />
                        </SwiperSlide>)
                    }

                </Swiper>
            </div>
            <div className='text-[#54595F] text-xs flex flex-col gap-y-5 mt-5 lg:hidden '>
                {
                    detailsArray1?.map((ele, i) => <p key={ele}>{ele}</p>)
                }
            </div>
        </section>
    )
}

export default HeroSwiper