import React from 'react';
import PopularBlogImg from '../../Assets/BlogPage/popular-blog.webp';

const blogCategories = ['Decoration Plan', 'Photography Event', 'Perfect Wedding Plan', 'Bridal Dress', 'Hair Style'];

const blogPopularTags = ['Wedding', 'Event', 'Bride', 'Dress', 'Reception', 'Gift', 'Groom']

const BlogCategorySection = () => {
    return (
        <div>
            <div className='md:w-80 w-full flex flex-col  gap-9'>
                <div className='md:w-80 w-full border shadow-xl p-4 flex flex-col gap-3 rounded-2xl'>
                    <h4 className='text-lg font-semibold'>Popular Posts</h4>
                    <div className='h-0 w-full flex items-center'>
                        <div className=' w-10 border-2 border-[#b40101]'></div>
                        <div className=' w-full border border-[#b40101]'></div>
                    </div>
                    <PopularBlogCard />
                    <PopularBlogCard />
                    <PopularBlogCard />
                </div>
                <div className='md:w-80 w-full border shadow-xl p-4 flex flex-col gap-3 rounded-2xl'>
                    <h4 className='text-lg font-semibold'>Categories</h4>
                    <div className='h-0 w-full flex items-center'>
                        <div className=' w-10 border-2 border-[#b40101]'></div>
                        <div className=' w-full border border-[#b40101]'></div>
                    </div>
                    <ul className=' list-disc pl-4 leading-8'>
                        {
                            blogCategories?.map((el, i) => (
                                <li className=' text-[#9a0016]' key={i}>
                                    <p>{el}</p>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div className='md:w-80 w-full border shadow-xl p-4 flex flex-col gap-3 rounded-2xl'>
                    <h4 className='text-lg font-semibold'>Popular Tags</h4>
                    <div className='h-0 w-full flex items-center'>
                        <div className=' w-10 border-2 border-[#b40101]'></div>
                        <div className=' w-full border border-[#b40101]'></div>
                    </div>
                    <div className='flex gap-3 flex-wrap'>
                        {
                            blogPopularTags?.map((el, i) => (
                                <div className='px-3 py-1 border border-gray-300 rounded-md' key={i}>{el}</div>

                            ))
                        }

                    </div>
                </div>

            </div>
        </div>
    )
}

export default BlogCategorySection;


const PopularBlogCard = () => {


    return (
        <div className='flex gap-2'>
            <div className='w-20'>
                <img src={PopularBlogImg} alt="" className='w-20' />
            </div>
            <div className='w-[75%] flex flex-col justify-center'>
                <h5 className=' text-sm'>10 Jul 2023</h5>
                <h5 className=' text-[15px] font-semibold' >10 Facts About Matrimony That Will Blow Your Mind.</h5>
            </div>
        </div>
    )
}