import React, { useEffect } from 'react'


import HeaderSection from '../Components/HeaderSection/HeaderSection'
import Categories from '../Components/Services/Categories'
import ContentPage from '../Components/Services/ContentPage'
import OurServices from '../Components/Services/OurServices'

import Image1 from '../Assets/Svg_Image/Decoration/decoration-1.svg'
import Image2 from '../Assets/Svg_Image/Decoration/decoration-2.svg'
import Image3 from '../Assets/Svg_Image/Decoration/decoration-3.svg'
import Image4 from '../Assets/Svg_Image/Decoration/decoration-4.svg'

import ImageP_1 from '../Assets/Svg_Image/Decoration/decoration-2_2.svg'
import ImageP_2 from '../Assets/Svg_Image/Decoration/decoration-2_1.svg'
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GA_ID);

const page = {
    title: "Modeling Decoration",
    mainTitle: "A Serenade of Elegance and Glamour",
    swiperArray: [Image1, Image2, Image3, Image4],
    ImageP_1, ImageP_2,
    solutionForCoupels_p: "At The Bliss Events, we don't just decorate spaces; we craft immersive experiences that resonate with the depth of your emotions and the uniqueness of your love story.  Our expertise lies not only in creating visually stunning designs but in infusing them with the soulful essence of your journey together. Each thematic concept is curated with precision, combining your vision with our creative finesse to sculpt spaces that mirror your dreams. From the ethereal ambiance to the exquisite detailing, every element is a testament to our commitment to crafting an experience that leaves an indelible mark on your hearts. craft an emotional masterpiece together, where every design choice whispers tales of your extraordinary love story. This description aims to convey the emotional depth and heartfelt commitment that the wedding planner brings to the Modeling Decoration services, emphasizing the fusion of emotions and artistic design in creating a memorable wedding experience.",
    solutionForCoupels_p2: "",
    about_p: "Step into a world where every detail echoes your love story, where emotions are woven into the very fabric of design. Our commitment extends far beyond aesthetics; it's about understanding the essence of your connection and manifesting it through our creations. With a palette of emotions and creativity at our disposal, we sculpt moments that transcend time. Each design element, from the grandeur of structural marvels to the delicacy of intricate adornments, is crafted to resonate with the depth of your emotions.",
    aboutPointArray: [
        "Dream Vision Exploration",
        "Creative Design Collaboration",
        "Timeline and Flow Harmonization",
        "Theme Craftsmanship",
        "Guest Enchantment",
    ],
    getFromThisService: "Our Modeling Decoration services aim to not only adorn spaces but also create a backdrop that encapsulates the essence of your union, a testament to your enduring love. Some of our services include: ",
    getFromThisService_Ponints: [
        "Structural Elegance Crafting: Beyond aesthetics, we mold structures that encapsulate emotions. Architectural elements reflect not just grandeur but the depth of your connection, echoing the strength of your bond.",
        "Visual Spectacle Creation: We breathe life into your dreams, creating a visual symphony that transcends imagination. From breathtaking centerpieces to architectural marvels, every element is designed to enrapture.",
        "Cultural touch addition: We provide you with the best ever cultural additions and beautiful touches to every event making it elegant and gorgeous.",
        "Innovative Design Collaboration: We merge innovation with sentimentality, curating designs that tell tales of love. Your preferences blend seamlessly with cutting-edge concepts, resulting in a magical amalgamation of emotions and aesthetics.",
        "Post-Celebration Reflection and Care: Even after the celebration ends, our connection continues. We reflect on the shared memories, ensuring every cherished moment is preserved in the tapestry of your love story.",
    ]
}

const DecorationPage = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Decoration Page" });
        window.scroll(0, 0)
    }, [])
    return (
        <section >
            <HeaderSection title={page.title} />
            <div className="flex justify-evenly items-start lg:flex-row flex-col-reverse max-w-[1600px] mx-auto">
                <Categories />
                <ContentPage {...page} />
            </div>
            <OurServices />
        </section>
    )
}

export default DecorationPage