import React, { useEffect } from 'react'

import HeaderSection from '../Components/HeaderSection/HeaderSection'
import Categories from '../Components/Services/Categories'
import ContentPage from '../Components/Services/ContentPage'
import OurServices from '../Components/Services/OurServices'

import Image1 from '../Assets/Svg_Image/Caterors/food-1.svg'
import Image2 from '../Assets/Svg_Image/Caterors/food-2.svg'
import Image3 from '../Assets/Svg_Image/Caterors/food-3.svg'
import Image4 from '../Assets/Svg_Image/Caterors/food-4.svg'

import ImageP_1 from '../Assets/Svg_Image/Caterors/food-2_1.svg'
import ImageP_2 from '../Assets/Svg_Image/Caterors/food-2_2.svg'
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GA_ID);

const page = {
    title: "Caterers",
    mainTitle: "Crafting Culinary Memories for Your Celebration",
    swiperArray: [Image1, Image2, Image3, Image4],
    ImageP_1, ImageP_2,
    solutionForCoupels_p: "With each dish, we blend expertise and heart, curating a menu that reflects your love story. Whether it’s the nostalgia of a family recipe or the innovation of contemporary cuisine, each flavor is a tribute to your journey.From the first bite to the last lingering taste, our cuisine transcends mere sustenance; it’s an ensemble of flavors that evoke memories, emotions, and moments of sheer delight. We understand that your celebration isn’t just an event; it's a tapestry of cherished moments, and our cuisine serves as a thread weaving through the fabric of your day. ",
    solutionForCoupels_p2: "We understand that your guests are an extension of your love, and our culinary expertise ensures they're treated to a feast that reflects the richness of your emotions. Each dish is meticulously prepared to tantalize the taste buds, leaving an impression that lingers long after the celebration ends.",
    about_p: "From the initial consultation, where we listen intently to your desires and emotions, to the meticulous planning and execution on your special day, we infuse love and care into every aspect of our service. We understand the significance of your celebration, and our commitment is to provide an unforgettable culinary experience that mirrors your unique story. With a menu tailored to your preferences, whether it's embracing cultural traditions or exploring contemporary fusions, our cuisine aims to create an emotional connection with your guests.",
    aboutPointArray: [
        "Personalized Menu Creation",
        "Consultation and Planning",
        "Cuisine Customization",
        "Menu Tasting Experience",
        "Full-Service Catering",
    ],
    getFromThisService: "Our service isn’t just about serving food; it’s about crafting memories, adding an emotional layer to your celebration, and ensuring every guest feels the warmth of your hospitality. Each culinary creation, every dish we prepare, is crafted with heartfelt dedication, aiming to tantalize taste buds and touch hearts.",
    getFromThisService_Ponints: [
        "Cuisine Customization: Offering a diverse range of cuisines, from traditional to contemporary, with each dish infused with heartfelt dedication.",
        "Full-Service Catering: Complete catering services encompassing menu design, food preparation, presentation, and service on the event day.",
        "Attention to Dietary Needs: Catering to special dietary requirements and ensuring every guest feels included and cared for.",
        "Emotional Atmosphere Creation: Working alongside you to curate an ambiance that complements the emotional resonance of your celebration.",
        "Professional Service Staff: Providing courteous and attentive service staff dedicated to ensuring your guests’ emotional satisfaction.",
    ]
}

const CaterersPage = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Catrers Page" });

        window.scroll(0, 0)
    }, [])
    return (
        <section >
            <HeaderSection title={page.title} />
            <div className="flex justify-evenly items-start lg:flex-row flex-col-reverse max-w-[1600px] mx-auto">
                <Categories />
                <ContentPage {...page} />
            </div>
            <OurServices />
        </section>
    )
}

export default CaterersPage