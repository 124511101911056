import React, { useEffect, useState } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
} from "@material-tailwind/react";
import Moment from 'react-moment';
import { Link } from "react-router-dom";

import { GoPerson } from 'react-icons/go';
import { FaRegComments } from 'react-icons/fa';

import BlogCategorySection from './BlogCategorySection';
import imageUrlBuilder from '@sanity/image-url';
import { client } from '../../sanity';

const BlogCards = () => {
    const [blogs, setBlogs] = useState([])
    const fetchData = async () => {
        const query = '*[_type == "blog"]';
        const blogs = await client.fetch(query);
        setBlogs(blogs)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className='max-w-[1600px] m-auto'>
            <div className='lg:w-10/12 w-11/12 m-auto py-10 flex md:flex-row flex-col-reverse md:justify-between justify-center 2xl:gap-24 gap-8'>
                <div>
                    <BlogCategorySection />
                </div>
                <div className='w-full '>
                    <div className='grid xl:grid-cols-2 grid-cols-1 justify-between items-stretch gap-x-2 gap-y-5'>
                        {
                            blogs.length > 0 &&
                            blogs?.map((ele, i) => <BlogCard key={i} {...ele} />)
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default BlogCards

const BlogCard = ({ blogimage, title, createdAt, _id }) => {
    const builder = imageUrlBuilder(client)

    return (
        <Card
            className="md:max-w-[13rem] lg:max-w-[20rem] mx-auto overflow-hidden">
            <Link to={`/blog/${_id}`} className="text-[#B03232] font-bold " >
                <CardHeader
                    floated={false}
                    shadow={false}
                    color="transparent"
                    className="m-0 rounded-none relative overflow-visible"
                >
                    <img src={`${builder.image(blogimage).url()}`} alt='blog-image' className=' object-cover aspect-[16/11]' />

                    <div className="bg-[#B40101] absolute lg:py-3 lg:px-5 px-2 py-1 text-white right-4 -bottom-10">
                        {/* <p className="font-bold">10/12</p> */}

                        <div className="flex flex-col text-center">
                            <Moment format="D" withTitle>
                                {createdAt}
                            </Moment>
                            <Moment format="MMM" withTitle>
                                {createdAt}
                            </Moment>
                        </div>
                    </div>
                </CardHeader>

                <CardBody >
                    <p className="flex gap-x-2 items-center text-[#323232]">
                        <GoPerson className="text-[#B40101]" size={25} />Admin
                    </p>
                </CardBody>
                <CardFooter className="!pt-2">
                    <h3 className="text-black lg:text-xl md:text-sm text-lg mb-3 line-clamp-2">
                        {title}
                    </h3>
                    <p className="text-[#B03232] font-bold " >
                        Read More
                    </p>
                </CardFooter>
            </Link>
        </Card>
    )
}