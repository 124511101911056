import React from 'react'
import Leaves1Img from '../../Assets/HeadrerSection/leave.svg'
import Leaves2Img from '../../Assets/HeadrerSection/leaves2.svg'

const HeaderSection = ({title, description}) => {
    return (
        <div className="bg-[#f7e1e4] ">
            <div className='max-w-[1600px] m-auto md:h-[17rem] h-44 flex'>
                <div className='lg:w-10/12 w-11/12 m-auto flex md:justify-between justify-center items-center'>
                    <div className="hidden md:flex xl:w-64 w-44 xl:h-44 h-40 justify-between ">
                        <img src={Leaves1Img} alt={"Leaves1Img"} className='xl:w-24 w-16' />
                        <img src={Leaves2Img} alt={"Leaves2Img"} className='xl:w-12 w-10 self-start' />
                    </div>
                    <div className='text-center'>
                        <h1 className="xl:text-7xl md:text-[54px] text-4xl font-extrabold font-['Cormorant_Garamond'] text-[#9D0F0F]">{title}</h1>
                        <h5 className="xl:text-2xl md:text-xl text-base font-bold md:mt-7 mt-6 font-['Cormorant_Garamond']">{description}</h5>
                    </div>
                    <div className="hidden md:flex xl:w-64 w-44 xl:h-44 h-40 justify-between ">
                        <img src={Leaves1Img} alt={"Leaves1Img"} className='xl:w-24 w-16' />
                        <img src={Leaves2Img} alt={"Leaves2Img"} className='xl:w-12 w-10 self-start' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderSection;
