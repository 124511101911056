import React, { useEffect } from 'react'
import HeaderSection from '../Components/HeaderSection/HeaderSection'
import ServicesCard from '../Components/ServicesPage/ServicesCard'
import MakeAnInquiry from '../Components/LandingPage/MakeAnInquiry/MakeAnInquiry'

const ServicesPage = () => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  const title = 'Service'
  const description = '"Crafting stunning websites that drive results."'
  return (
    <div>
      <HeaderSection title={title} description={description} />
      <ServicesCard />
      <MakeAnInquiry negativeMargin={false} marginBottom={false} />
    </div>
  )
}

export default ServicesPage
