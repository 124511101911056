import React, { useEffect } from 'react'

import HeaderSection from '../Components/HeaderSection/HeaderSection'
import Categories from '../Components/Services/Categories'
import ContentPage from '../Components/Services/ContentPage'
import OurServices from '../Components/Services/OurServices'

import Image1 from '../Assets/Svg_Image/Haldi/haldi-1.svg'
import Image2 from '../Assets/Svg_Image/Haldi/haldi-2.svg'
import Image3 from '../Assets/Svg_Image/Haldi/haldi-3.svg'
import Image4 from '../Assets/Svg_Image/Haldi/haldi-4.svg'


import ImageP_1 from '../Assets/Svg_Image/Haldi/haldi-2_2.svg'
import ImageP_2 from '../Assets/Svg_Image/Haldi/haldi-2_1.svg'
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GA_ID);

const page = {
    title: "Haldi Event",
    mainTitle: "Celebrating Love in Turmeric Hues",
    swiperArray: [Image1, Image2, Image3, Image4],
    ImageP_1, ImageP_2,
    solutionForCoupels_p: "The scent of turmeric and the vibrant hues linger in our memories, serving as a testament to the love and camaraderie that filled the air. Witnessing the radiant smiles, the playful teasing, and the tender moments shared during the Haldi ceremony fills us with an overwhelming sense of joy.  We carry the echoes of laughter, the essence of traditions, and the warmth of familial bonds with us, deeply imprinted in our souls.",
    solutionForCoupels_p2: "We reflect on the significance of every ritual, every glance exchanged between family members, and the intricate details that made this ceremony uniquely yours. It's in these moments that we realize the privilege of being a part of your journey—a journey woven with threads of love, heritage, and cherished traditions.",
    about_p: "The details of the Haldi ceremony are meticulously handled with tenderness. From the turmeric paste preparations to the arrangement of auspicious items, every element is thoughtfully curated to honor the sanctity of the occasion. As the day unfolds, our team stands as guardians of tradition and joy. We ensure that the rituals flow seamlessly, guiding family and friends through each moment with reverence and grace. We honor the cultural sanctity while embracing the joyous laughter and playful camaraderie that accompany the ceremony..",
    aboutPointArray: [
        "Customized Haldi Accessories",
        "Traditional Ritual Guidance",
        "Cultural Sensitivity and Etiquette",
        "Coordination with Beauty Services",
        "Themed Activities and Games",
    ],
    getFromThisService: "We orchestrate moments of joy and merriment, infusing the atmosphere with lively music, traditional performances, and engaging activities that bring everyone closer together in celebration.",
    getFromThisService_Ponints: [
        "Timing and Coordination: Planning the schedule for the Haldi ceremony, including timing for the arrival of family and friends, ritual performances, and any cultural customs associated with the ceremony.",
        "Guest Comfort and Hospitality: Providing assistance to guests by ensuring their comfort, offering refreshments, and overseeing any special requirements or accommodations.",
        "Entertainment and Music: Organizing traditional music or performances to add cultural vibrancy and joy to the ceremony.",
        "Assistance for the Couple: Offering support and assistance to the bride and groom, ensuring they are comfortable and have everything they need for the ceremony.",
        "Clean-Up and Post-Event Management: Managing the clean-up after the ceremony, ensuring that the venue is left in its original state and handling any necessary follow-ups or arrangements.",
    ]
}

const HaldiPage = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Haldi Page" });
        window.scroll(0, 0)
    }, [])
    return (
        <section >
            <HeaderSection title={page.title} />
            <div className="flex justify-evenly items-start lg:flex-row flex-col-reverse max-w-[1600px] mx-auto">
                <Categories />
                <ContentPage {...page} />
            </div>
            <OurServices />
        </section>
    )
}

export default HaldiPage