import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import './styles.css';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';

import WeddingImg from '../../../Assets/AboutUSPage1/project-6.jpg.webp'
import WeddingImg2 from '../../../Assets/AboutUSPage1/project-1.jpg.webp'
import WeddingImg3 from '../../../Assets/AboutUSPage1/project-2.jpg.webp'

const ourServicesCardInfo = [
    {
        title: "Decoration plan",
        desc: "Elit at donec",
        img: WeddingImg,

    },
    {
        title: "Wedding Bliss",
        desc: "Elit at donec",
        img: WeddingImg2,

    },
    {
        title: "Just Two Of US",
        desc: "Elit at donec",
        img: WeddingImg3,

    },
];


const OurCompletedProjects = () => {

    return (
        <div className='max-w-[1600px] m-auto'>
            <div className='w-11/12 lg:w-10/12  m-auto text-center py-12'>
                <h1 className=' lg:text-2xl text-xl'>Our Completed Projects</h1>
                <h2 className=' text-3xl font-semibold text-[#B40101] mt-3'>Our Innovative Wedding Service Project Solutions</h2>

                <Swiper
                    // slidesPerView={3}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    // loop={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper pb-20 overflow-y-visible mt-8"
                    breakpoints={{
                        300: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        600: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        }
                    }}
                >
                    {
                        ourServicesCardInfo?.map((ele, i) => (

                            <SwiperSlide key={i}><OurServicesCard {...ele} key={i} /></SwiperSlide>
                        ))
                    }
                </Swiper>

            </div>
        </div>
    )
}

export default OurCompletedProjects;

const OurServicesCard = ({ title, desc, img }) => {

    return (
        <div className='lg:w-auto md:w-[20rem] relative lg:pb-20 pb-24'>

            <div className='z-10'><img src={img} alt={title} className='w-full lg:h-52 2xl:h-72  z-20 ' />
            </div>
            <div className='lg:w-[80%] w-[92%] m-auto justify-between absolute  left-1/2 transform -translate-x-1/2 lg:-translate-y-1/2 -translate-y-1/3 z-50 rounded-xl bg-white text-left px-4 py-5 shadow-lg'>
                {/* <h3 className=' xl:text-[21px] lg:text-[16px] text-[#B40101] font-bold'>{ }</h3> */}
                <div className='flex justify-between  items-center'>
                    <div className='flex flex-col gap-2'>
                        <h5 className='xl:text-lg text-base text-[#B40101] font-bold' >
                            {title}
                        </h5>
                        <p className='text-xs'>
                            {desc}
                        </p>
                    </div>
                    <div className='w-16- flex justify-end items-end'>
                        <IoIosArrowForward size={25} className='text-[#B40101] ' />
                    </div>
                </div>

            </div>
        </div>
    )
}