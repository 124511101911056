import { Card, CardBody, CardHeader, Typography } from '@material-tailwind/react'
import React from 'react'

const Member = ({ img, name, designation }) => {
    return (
        <div className='max-w-[17rem] md:max-w-[13rem]  lg:w-72 lg:max-w-[17rem] mx-auto' >
            <div className=' flex justify-end items-end bg-white rounded-full '>
                <img src={img} alt={name} className='w-full object-cover aspect-square  rounded-full ' />

            </div>

            <div className='py-2 mt-4 text-center bg-white rounded-lg shadow-lg relative'>
                <Typography variant='paragraph' className='md:text-lg text-base font-bold text-black'>
                    {name}
                </Typography>
                <Typography variant='paragraph' className='text-sm text-[#323232] font-semibold '>
                    {designation}
                </Typography>
                <div className=' lg:block md:hidden block p-4 rounded-full bg-[#B40101] absolute right-3 top-4' />
            </div>
        </div>
    )
}

export default Member