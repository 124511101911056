import React from 'react'
import { FaLocationDot } from 'react-icons/fa6'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { MdEmail } from 'react-icons/md'


import treeBranch from '../../../Assets/ContactUs/treeBranch.svg'
import collection from '../../../Assets/ContactUs/roundStripCollection.svg'
import Form from './Form'

const ContactForm = () => {
    return (
        <section className='max-w-[1600px] mx-auto'>
            <div className='w-11/12 mx-auto lg:w-10/12 relative lg:mb-52'>
                <h1 className='text-[#B40101] text-xl md:text-3xl lg:text-5xl font-bold text-center mt-16 mb-10'>
                    Don't Hesitate To Contact Us
                </h1>
                <div className='flex justify-between items-start flex-col lg:flex-row'>
                    <div className='w-full lg:w-8/12 shadow-xl py-10 px-6 rounded-md flex justify-center'>
                        {/* <Form /> */}
                        <iframe aria-label='The Bliss Events - Contact Us Form' frameborder="0"
                            className='h-[598px] lg:w-10/12 w-full border-none'
                            src='https://forms.zohopublic.in/gbjbuzz/form/TheBlissEventsContactUsForm/formperma/gv9EYYQnvloAltFkc6v2Hs6qDPV365J0EGq-Vl3BmI4'></iframe>
                    </div>
                    <div className='w-full lg:w-[30%] my-12 lg:my-0 flex flex-col gap-y-8'>
                        <a
                            href="https://maps.app.goo.gl/PLT5THsvTzhEDXYj6"
                            target="_blank"
                            rel="noopener noreferrer"
                            className='flex items-center gap-x-4 w-full shadow-lg  p-4 rounded-md max-w-sm lg:w-full mx-auto '>
                            <div className='p-4 rounded-full border border-[#9D0F0F]'>
                                <FaLocationDot size={30} className='text-[#9D0F0F]' />
                            </div>
                            <div>
                                <h2 className='text-2xl font-bold '>
                                    Our Location
                                </h2>
                                <p className='text-xs text-[#54595F] mt-2'>
                                    Nagpur, Maharashtra, IN
                                </p>
                            </div>
                        </a>
                        <a
                            href="mailto:theblissevents1985@gmail.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className='flex  items-center gap-x-4 w-full shadow-lg  p-4 rounded-md max-w-sm lg:w-full mx-auto'>
                            <div className='p-4 rounded-full border border-[#9D0F0F]'>
                                <MdEmail size={30} className='text-[#9D0F0F]' />
                            </div>
                            <div>
                                <h2 className='text-2xl font-bold '>
                                    Send An Email
                                </h2>
                                <p className='text-xs text-[#54595F] mt-2'>
                                    theblissevents1985@gmail.com
                                </p>
                            </div>
                        </a>
                        <a
                            href="tel:+917021927602"
                            target="_blank"
                            rel="noopener noreferrer"
                            className='flex  items-center gap-x-4 w-full shadow-lg  p-4 rounded-md max-w-sm lg:w-full mx-auto bg-white'>
                            <div className='p-4 rounded-full border border-[#9D0F0F]'>
                                <BsFillTelephoneFill size={30} className='text-[#9D0F0F]' />
                            </div>
                            <div>
                                <h2 className='text-2xl font-bold '>
                                    Phone Number
                                </h2>
                                <p className='text-xs text-[#54595F] mt-2'>
                                    +91 70219 27602
                                </p>
                            </div>
                        </a>
                    </div>

                </div>
            </div>
            <div className='hidden lg:block  -z-20'>
                <div className=' absolute top-1/2 -z-10 left-0 w-40'>
                    <img src={collection} alt="red Strips COllection" />
                </div>
                <div className=' absolute top-full right-0 -z-20 '>
                    <img src={treeBranch} alt="tree Branch" />
                </div>
            </div>
        </section>
    )
}

export default ContactForm