import React, { useEffect } from 'react'
import HeaderSection from '../Components/HeaderSection/HeaderSection'
import TestimonialCard from '../Components/TestimonialsPage/TestimonialCard/TestimonialCard'

const TestimonialPage = () => {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <div className=''>

            <HeaderSection title={"Testimonials"} description={"Read what our valued clients have to say about their journey with us."} />
            <TestimonialCard />

        </div>
    )
}

export default TestimonialPage;

