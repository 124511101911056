import React, { useEffect } from 'react'
import HeaderSection from '../Components/HeaderSection/HeaderSection'
import BlogCards from '../Components/BlogPage/BlogCards'
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GA_ID);

const BlogPage = () => {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Blog page" });
    window.scroll(0, 0)
  }, [])

  return (
    <div>
      <HeaderSection title={"Blogs"} />
      <BlogCards />

    </div>
  )
}

export default BlogPage
