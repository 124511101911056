import React from 'react'
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
} from "@material-tailwind/react";
import Moment from "react-moment";
import { GoPerson } from 'react-icons/go'
import { Link } from "react-router-dom";
import imageUrlBuilder from '@sanity/image-url';

import { client } from '../../../../sanity';

const BlogCard = ({ blogimage, title, createdAt, _id, id }) => {
    const builder = imageUrlBuilder(client)

    return (

        <Card
            data-aos="flip-left"
            data-aos-duration="1000"
            data-aos-delay={`${id * 100}`}

            className="md:max-w-[13rem] lg:max-w-[20rem] mx-auto overflow-hidden">
            <Link to={`/blog/${_id}`} className="text-[#B03232] font-bold " >

                <CardHeader
                    floated={false}
                    shadow={false}
                    color="transparent"
                    className="m-0 rounded-none relative overflow-visible"
                >
                    <img src={`${builder.image(blogimage).url()}`} alt='blog-image' className=' object-cover aspect-[16/11]' />

                    <div className="bg-[#B40101] absolute lg:py-3 lg:px-4 px-2 py-1 text-white right-4 -bottom-10">
                        {/* <p className="font-bold">10/12</p> */}

                        <div className="flex flex-col text-center">
                            <Moment format="D" withTitle>
                                {createdAt}
                            </Moment>
                            <Moment format="MMM" withTitle>
                                {createdAt}
                            </Moment>
                        </div>
                    </div>
                </CardHeader>

                <CardBody >
                    <p className="flex gap-x-2 items-center text-[#323232]">
                        <GoPerson className="text-[#B40101]" size={25} />Admin
                    </p>
                </CardBody>
                <CardFooter className="!pt-2">
                    <h3 className="text-black lg:text-xl md:text-sm text-lg mb-3 line-clamp-2">
                        {title}
                    </h3>
                    <p className="text-[#B03232] font-bold " >
                        Read More
                    </p>
                </CardFooter>
            </Link>
        </Card>
    )
}

export default BlogCard