import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Virtual } from 'swiper/modules'
import { Pagination, Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import WedPlaImg from '../../Assets/DestinationWedding/wedding-planning.webp';
import iconImg from '../../Assets/DestinationWedding/Vector 13.svg';
import iconImg1 from '../../Assets/DestinationWedding/Vector 14.png';


import doubleTick from '../../Assets/double-tick.svg'


const WeddingAboutServices = ({ aboutPointArray, servicesList, getFromThisService_Ponints, getFromThisService, about_p }) => {
    const [swiperRef, setSwiperRef] = useState(null);

    return (
        <section className='w-11/12 xl:w-10/12 lg:w-11/12 mx-auto mt-12 flex lg:flex-row flex-col justify-between'>

            <div className='lg:hidden w-full h-auto overflow-auto whitespace-nowrap '>

                {
                    servicesList?.map((el, i) => (

                        <div className='w-9/12 md:w-8/12 inline-block items-center -mt-6 mr-6 md:mr-10' key={i}>
                            <img src={el.img} alt="wedding-image" className='w-full object-fill' />
                            {
                                el.title &&

                                <div className='relative '>
                                    <img src={iconImg1} alt="wedding-icon" className='w-full -mt-14 md:-mt-20' />
                                    <div className='flex items-center justify-center gap-x-2 absolute top-1/2 left-1/2 -translate-x-1/2 z-50 font-[Lexend] w-[40%] md:text-2xl'>
                                        <p className=' text-[#B40101] self-center w-1/3'>0{i + 1}</p>
                                        <p className=' text[#262E3A]'>{el.title}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    ))
                }
            </div>

            <div className={`hidden lg:grid w-[54%] grid-cols-3 gap-x-[0.02rem] h-0 mt-12`}>
                {
                    servicesList?.map((el, i) => (
                        <div className=' flex flex-col items-center -mt-6' key={i}>
                            <img src={el.img} alt="wedding-image" className='w-full object-fill' />
                            {
                                el.title &&
                                <div className='relative -mx-4 2xl:-mx-[18px]'>
                                    <img src={iconImg} alt="wedding-icon" className='w-full -mt-10' />
                                    <div className='flex items-center justify-center gap-x-2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-[104%] z-50 font-[Lexend] text-xs w-[40%]'>
                                        <p className=' text-[#B40101] self-center'>0{i + 1}</p>
                                        <p className=' text[#262E3A]'>{el.title}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    ))
                }

            </div>
            <div className='lg:w-5/12 w-full'>
                <div>
                    <h2 className="md:text-[26px]  text-xl font-semibold text-[#051145] font-['Lexend'] mt-5">
                        About This Service
                    </h2>
                    <p className='text-[#54595F] text-sm mt-5 '>
                        {about_p}
                    </p>
                    <div className='w-11/12 mx-auto flex flex-col gap-y-3 text-sm text-[#54595F] mt-5'>
                        {
                            aboutPointArray?.map((ele, i) => <p key={i}>{ele}</p>)
                        }
                    </div>
                </div>
                <div className='mb-12'>
                    <h2 className="md:text-[26px] text-xl font-semibold text-[#051145] font-['Lexend'] mt-5">
                        What You Will Get From This Service
                    </h2>
                    <p className='text-[#54595F] text-sm  mt-5'>
                        {getFromThisService}
                    </p>
                    <div className=' flex flex-col gap-y-3 text-sm text-[#54595F] mt-5'>
                        {
                            getFromThisService_Ponints?.map((ele, i) => <span key={i} className='flex gap-x-2 items-start'>
                                <img src={doubleTick} alt="double-tics" />
                                <p>{ele}</p>
                            </span>
                            )
                        }
                    </div>
                </div>
            </div>

        </section>
    )
}

export default WeddingAboutServices;