import React from 'react'

import weedingIcon from '../../Assets/ServicesPage/weedingIcon.svg';
import photographIcon from '../../Assets/ServicesPage/photographIcon.svg';
import interorIcon from '../../Assets/ServicesPage/InteriorIcon.svg';
import brideIcon from '../../Assets/ServicesPage/brideIcon.svg';

import weedingWhiteIcon from '../../Assets/ServicesPage/wedding-icon-white.svg';
import photographWhiteIcon from '../../Assets/ServicesPage/photographIcon-white.svg';
import interorWhiteIcon from '../../Assets/ServicesPage/InteriorIcon-white.svg';
import brideWhiteIcon from '../../Assets/ServicesPage/brideIcon-white.svg';

import { Link } from 'react-router-dom';

const servicesCard = [
    {
        title: "Wedding Reception",
        icon: weedingIcon,
        icon2: weedingWhiteIcon,
        desc: "Let us curate a reception that reflects your unique style & leaves a lasting impression",
        link: "/service/reception"
    },
    {
        title: "Photography Event",
        icon: photographIcon,
        icon2: photographWhiteIcon,
        desc: "Frame the moments that matter, turning your wedding into a visual masterpiece",
        link: "/service/photography"
    }, {
        title: "Interior Decoration",
        icon: interorIcon,
        icon2: interorWhiteIcon,
        desc: "Creating a captivating environment that reflects your style and enhances the overall experience.",
        link: "/service/decoration"
    }, {
        title: "Bride Makeup",
        icon: brideIcon,
        icon2: brideWhiteIcon,
        desc: "Our skilled artists curate a stunning look that accentuates your natural beauty, ensuring you shine on your special day.",
        link: "/service/bridal-makeup"
    },

]


const OurServices = () => {
    return (
        <div className=' max-w-[1600px] m-auto bg-[#f3e2e2] py-20'>
            <div className=' lg:w-10/12 w-11/12 m-auto'>
                <h1 className='text-[#B40101]'>Our Services</h1>
                <div className='flex lg:flex-row flex-col justify-between gap-y-4'>
                    <h1 className='lg:w-[48%] w-full lg:text-4xl text-lg font-semibold font-[Lexend] text-[#051145] lg:!leading-[2.8rem]'>
                        We're Providing The Best Digital
                        Wedding Solution
                    </h1>
                    <p className='lg:w-[40%] w-full text-[#54595F] lg:text-base text-sm'>Discover an exceptional range of offerings designed to elevate every moment of your special day.</p>

                </div>
                <div className=' flex md:flex-wrap md:flex-row flex-col lg:justify-between md:justify-evenly gap-x-7 justify-center items-center gap-y-6 lg:mt-10 mt-6 '>
                    {
                        servicesCard.map((el, i) => (
                            <div key={i} className='group w-64 h-64 flex flex-col gap-y-2 p-5 bg-white justify-center shadow-[0px_4px_20px_rgba(5,17,69,0.08)] transition-all duration-500 ease-linear'>
                                <div className='w-16 h-16 flex items-center justify-center rounded-full border border-solid border-[#B40101]'>
                                    <div className='w-[56px]  h-[56px] flex justify-center items-center bg-[rgba(180,1,1,0.08)] rounded-full group-hover:bg-[#b40101] transition-all duration-500 ease-linear'>
                                        <img src={el.icon} alt="weeding-card-icon" className='w-8 group-hover:hidden transition-all duration-500 ease-linear' />
                                        <img src={el.icon2} alt="weeding-card-icon" className='w-7 group-hover:block hidden transition-all duration-500 ease-linear' />
                                    </div>
                                </div>
                                <h4 className=' text-xl text-[#051145] font-semibold font-[Lexend]'>{el.title}</h4>
                                <p className='text-[#54595F] line-clamp-4'>{el.desc}</p>


                            </div>
                        ))
                    }
                </div>

            </div>
        </div>
    )
}

export default OurServices
