import React, { useEffect } from 'react'

import HeaderSection from '../Components/HeaderSection/HeaderSection'
import Categories from '../Components/Services/Categories'
import ContentPage from '../Components/Services/ContentPage'
import OurServices from '../Components/Services/OurServices'

import Image1 from '../Assets/Svg_Image/Reception/reception-1.svg'
import Image2 from '../Assets/Svg_Image/Reception/reception-2.svg'
import Image3 from '../Assets/Svg_Image/Reception/reception-3.svg'
import Image4 from '../Assets/Svg_Image/Reception/reception-4.svg'

import ImageP_1 from '../Assets/Svg_Image/Reception/recepton-2-1.svg'
import ImageP_2 from '../Assets/Svg_Image/Reception/recepton-2-2.svg'
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GA_ID);

const page = {
    title: "Wedding Reception",
    mainTitle: "Reception: Where Love Takes Center Stage",
    swiperArray: [Image1, Image2, Image3, Image4],
    ImageP_1, ImageP_2,
    solutionForCoupels_p: " The reception is the heart of your celebration, where laughter echoes, tears of joy fall, and love dances in the air. With this understanding, we meticulously craft a plan that aligns perfectly with your aspirations, infusing every detail with a touch of magic.   From the breathtaking decor to the heartfelt speeches, from the rhythmic dance moves to the toasts filled with love and laughter, your reception is an intricate tapestry of emotions. Our mission is to capture every nuance, every joyful tear, and every jubilant smile, ensuring that these moments are not just experienced but preserved for eternity.",
    solutionForCoupels_p2: "Our team of dedicated professionals, with hearts brimming with passion, stands ready to cater to your every need. We anticipate your desires before they are even voiced, ensuring that you and your guests are treated with the utmost care and hospitality. From serving exquisite culinary delights to offering a listening ear, we are committed to curating an experience that surpasses your expectations.",
    about_p: "Every table setting, floral arrangement, and lighting fixture is chosen with tender care and attention. The atmosphere is imbued with warmth, radiance, and an ambiance that whispers of romance, creating an enchanting space where love and celebration intertwine. But beyond the logistics and the aesthetics, our reception services are infused with heartfelt sentiment. We share in your excitement, your nerves, and your unbridled happiness. Witnessing the pure elation on your faces as you celebrate love surrounded by those who matter most is what fuels our commitment and passion.",
    aboutPointArray: [
        "Venue Selection and Coordination",
        "Decor and Design",
        "Entertainment Arrangements",
        "Catering Services",
        "Timeline and Itinerary Planning",
    ],
    getFromThisService: "As the evening unfolds and the music fills the air, our commitment to your happiness only intensifies. We stand as silent guardians, ensuring that every fleeting second is filled with blissful moments. Whether it's coordinating the dance floor, gently guiding the schedule, or discreetly managing unforeseen hiccups, our dedication remains unwavering.",
    getFromThisService_Ponints: [
        "Coordination and Management: Overseeing the entire reception, ensuring that everything runs according to plan and addressing any unforeseen issues.",
        "Guest Services: Assisting with seating arrangements, guest accommodations, transportation, and any special requests or accommodations.",
        "Vendor Coordination: Liaising with various vendors such as photographers, videographers, florists, and rental companies to synchronize services.",
        "On-Site Assistance: Being present on the day to manage setup, greet guests, oversee proceedings, and handle any last-minute adjustments.",
        "Post-Event Wrap-Up: Ensuring all items are accounted for, assisting with breakdown and cleanup, and addressing any final details or follow-ups.",
    ]
}

const ReceptionPage = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "REception Page" });
        window.scroll(0, 0)
    }, [])
    return (
        <section>
            <HeaderSection title={page.title} />
            <div className="flex justify-evenly items-start lg:flex-row flex-col-reverse max-w-[1600px] mx-auto">
                <Categories />
                <ContentPage {...page} />
            </div>
            <OurServices />
        </section>
    )
}

export default ReceptionPage