import React, { useState } from 'react'
import Herosection1 from '../../../Assets/LandingPage/Herosection/Herosection1.svg';
import Herosection2 from '../../../Assets/LandingPage/Herosection/Herosection2.svg';
import LeavesImg from '../../../Assets/LandingPage/Herosection/leaves.svg';
import RedFlowerImg from '../../../Assets/LandingPage/Herosection/red-flower.svg';
import WhiteFlowerImg from '../../../Assets/LandingPage/Herosection/white-flower.svg';
import BgImage1 from '../../../Assets/LandingPage/Herosection/hero-shape-3 2.svg';
import BgImage2 from '../../../Assets/LandingPage/Herosection/hero-shape-5 1.svg';
import { RxDashboard } from 'react-icons/rx';
import InputField from '../InputField/InputField';
import { BsPeopleFill, BsSearch } from 'react-icons/bs'
import { Button } from '@material-tailwind/react';

import { Link as ScrollLink } from 'react-scroll';

const Herosection = () => {
    const [search, setSearch] = useState('')
    const [isVideoPlaying, setIsVideoPlaying] = useState(false)
    const [searchFieldShow, setSearchFieldShow] = useState(false)

    const handleSerachClick = () => {
        console.log(search)
        setSearch('')
    }
    return (
        <div className='bg-[#f7e1e4] lg:h-[90vh] md:flex justify-center items-center relative'>
            <div className='max-w-[1600px] m-auto md:pt-5 pt-7'>
                <div className='lg:w-10/12 w-11/12 m-auto pb-10 lg:pb-4 '>
                    <div >
                        {/* <div className='block md:hidden'>
                            {
                                searchFieldShow
                                    ?
                                    <div className=' w-full flex justify-end gap-2 items-center mb-8'>
                                        <div className='h-11 md:flex hidden justify-center items-center gap-1 bg-[#5465FF] rounded-lg text-sm px-2  text-white'>
                                            <RxDashboard />
                                            Category
                                        </div>
                                        <InputField value={search} setValue={setSearch} handleSerachClick={handleSerachClick} />
                                    </div>
                                    :
                                    <div className='flex justify-end mb-2'>
                                        <Button
                                            size="md"
                                            // disabled={!value}
                                            className=" rounded-2xl bg-[#B40101] !px-4"
                                            onClick={() => setSearchFieldShow(true)}
                                        >
                                            <BsSearch size={16} />
                                        </Button>
                                    </div>
                            }
                        </div> */}
                        {/* <div className=' w-full hidden md:flex justify-end gap-2 items-center mb-8'>
                            <div className='h-11 flex justify-center items-center gap-1 bg-[#5465FF] rounded-lg text-sm px-2  text-white'>
                                <RxDashboard />
                                Category
                            </div>
                            <InputField value={search} setValue={setSearch} handleSerachClick={handleSerachClick} />
                        </div> */}
                    </div>
                    <div className=' flex md:flex-row flex-col-reverse  justify-between items-end'>
                        <div className='md:w-[45%] w-full self-center'>
                            <h2
                                data-aos="fade-right"
                                data-aos-duration="1000"
                                data-aos-delay="100"
                                className=' text-[#B03232] 3xl:text-5xl lg:text-[46px] md:text-[31px] text-[30px] uppercase '>
                                Welcome To
                            </h2>
                            <h2
                                data-aos="fade-right"
                                data-aos-duration="1000"
                                data-aos-delay="100"
                                className=' text-[#B03232] 3xl:text-5xl lg:text-[46px] md:text-[31px] text-[30px] uppercase font-semibold'>
                                The Bliss Events
                            </h2>
                            <h2 className=' lg:text-2xl xl:text-3xl md:text-xl text-lg text-[#49516F] lg:mt-8 mt-6'>Creating lovely</h2>
                            <h2 className=' lg:text-3xl xl:text-[36px]  md:text-2xl text-xl font-bold text-[#49516F] mt-1'>
                                Wedding stories
                            </h2>
                            <h4 className='md:text-lg  text-base  my-5 text-[#323232] font-light  '>
                                Step into the mesmerizing realm of weddings where dreams come to life! Experience the ethereal beauty and magic that awaits in our enchanting world of celebrations. Join us on a journey through the magic we create.
                            </h4>
                            <ScrollLink
                                to='Inquiry'
                                spy={true}
                                smooth={true}
                                offset={0}
                                duration={500}
                                className='md:px-4 md:py-2 px-3 py-1  bg-[#B40101] text-white md:text-lg text-base font-medium rounded-lg '>
                                Register Now
                            </ScrollLink>
                        </div>
                        <div className='md:w-[56%] w-full 2xl:h-[33rem] lg:h-[30rem] h-[26rem] hidden relative md:flex md:justify-between justify-center gap-20 '>
                            <div className='3xl:p-[35%] xl:p-[36%] lg:p-[40%] md:p-[41%] p-[34%] bg-[#f3d2d7] absolute rounded-full xl:translate-x-[33%] xl:translate-y-[6%] lg:translate-x-[22%] lg:translate-y-[6%] md:translate-x-[10%] md:translate-y-[10%] z-50'>
                            </div>
                            <div
                                data-aos="fade-down"
                                data-aos-duration="1000"
                                data-aos-delay="100"
                                className='relative lg:w-[33%] xl:w-[35%] w-[32%] py-4 self-start rounded-tl-full rounded-tr-full border border-[#B40101] lg:ml-20 lg:mt-12 ml-10 mt-4 z-50'>
                                <img
                                    src={Herosection1} alt={"Weeding ceremony"} className=' relative w-full -bottom-2 left-10 ' />
                                <img src={LeavesImg} alt={"Leaves"} className=' lg:w-28 w-24  bottom-4 absolute 2xl:-translate-x-[4.6rem] 2xl:-translate-y-36 3xl:-translate-y-44 lg:-translate-y-32 md:-translate-x-[4rem] md:-translate-y-20' />
                            </div>
                            <div
                                data-aos="fade-left"
                                data-aos-duration="1000"
                                data-aos-delay="100"
                                className='relative w-[33%] self-end 3xl:mb-20 2xl:mb-32 xl:mb-28 mb-32 2xl:mr-9 lg:mr-0 md:mr-10 rounded-tl-full rounded-tr-full border border-[#B40101] z-[100]'>
                                <img src={Herosection2} alt={"Weeding ceremony"} className='relative w-[100%] lg:right-[18%] lg:translate-y-[16%] right-[18%] translate-y-[26%] z-50' />
                                <img src={WhiteFlowerImg} alt={"WhiteFlowerImg"} className=' lg:w-28 w-20  bottom-4 absolute 2xl:-translate-x-[2rem] 2xl:-translate-y-36 3xl:-translate-y-44 lg:-translate-y-24 md:-translate-x-8 md:-translate-y-16 -z-10' />
                                <img src={RedFlowerImg} alt={"RedFlowerImg"} className=' lg:w-12 w-10 absolute right-1 bottom-1' />
                            </div>
                        </div>

                        <div className=' w-full h-80 flex md:hidden my-6'>
                            <div className=' w-[20rem] h-[20rem] m-auto bg-[#f3d2d7] rounded-full z-50 flex justify-between relative'>
                                <div className='relative w-[38%] self-start rounded-tl-full rounded-tr-full border border-[#B40101] z-50 translate-x- -translate-y-[20%]'>
                                    <img src={Herosection1} alt={"Weeding ceremony"} className='w-32 bottom-4 translate-x-1 translate-y-[10%] ' />

                                </div>
                                <div className='relative w-[37%] self-end rounded-tl-full rounded-tr-full border border-[#B40101] z-50 -translate-x-[28%] -translate-y-[30%]'>
                                    <img src={Herosection2} alt={"Weeding ceremony"} className=' w-[100%] translate-x-[12%] translate-y-[8%] -z-20' />
                                </div>
                                <img src={LeavesImg} alt={"LeavesImg"} className=' lg:w-28 w-24  absolute z-50 bottom-0 right-1/2' />
                                <img src={WhiteFlowerImg} alt={"WhiteFlowerImg"} className=' lg:w-28 w-24 absolute top-4 right-[32%] rotate-[20deg]' />
                            </div>

                        </div>
                    </div>

                </div>

            </div>
            <img src={BgImage1} alt={"BgImage1"} className=' lg:w-32 w-20 absolute right-0 top-40' />
            <img src={BgImage2} alt={"BgImage2"} className='hidden md:block lg:w-52 w-40 absolute right-0 bottom-1' />
        </div>
    )
}

export default Herosection