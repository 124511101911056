import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'

import 'swiper/css'

import doubleTick from '../../Assets/double-tick.svg'


const ContentPage = ({ aboutPointArray, swiperArray, ImageP_1, ImageP_2, solutionForCoupels_p2, solutionForCoupels_p, getFromThisService_Ponints, getFromThisService, about_p, mainTitle }) => {
    return (
        <section className='w-11/12 lg:w-[55%] md:w-10/12 mx-auto mt-12'>
            <div className='lg:w-[95%] '>
                <div>
                    <Swiper
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay]}
                        className='mySwiper'
                    >
                        {
                            swiperArray?.map((ele, i) => <SwiperSlide key={i}>
                                <img src={ele} alt={`reception ${i}`} />
                            </SwiperSlide>)
                        }

                    </Swiper>
                </div>

                <div>
                    <h2 className="md:text-3xl text-2xl font-semibold text-[#051145] font-['Lexend'] mt-5">
                        {mainTitle}
                    </h2>
                    <p className='text-[#54595F] text-sm mt-5'>
                        {solutionForCoupels_p}
                    </p>
                    <p className='text-[#54595F] text-sm mt-2'>
                        {solutionForCoupels_p2}
                    </p>
                </div>
                <div className='mt-3 flex justify-start items-stretch w-full'>
                    <img src={ImageP_1} alt={"reception - 1"} className=' w-1/3 ' />
                    <img src={ImageP_2} alt={"reception - 1"} className=' aspect-video w-2/3 ' />
                </div>
                <div>
                    <h2 className="md:text-3xl text-2xl font-semibold text-[#051145] font-['Lexend'] mt-5">
                        About This Service
                    </h2>
                    <p className='text-[#54595F] text-sm mt-5'>
                        {about_p}
                    </p>
                    <div className='w-11/12 mx-auto flex flex-col gap-y-3 text-sm text-[#54595F] mt-5'>
                        {
                            aboutPointArray?.map((ele, i) => <p key={i}>{ele}</p>)
                        }
                    </div>
                </div>
                <div className='mb-12'>
                    <h2 className="md:text-3xl text-2xl font-semibold text-[#051145] font-['Lexend'] mt-5">
                        What You Will Get From This Service
                    </h2>
                    <p className='text-[#54595F] text-sm mt-5'>
                        {getFromThisService}
                    </p>
                    <div className=' flex flex-col gap-y-3 text-sm text-[#54595F] mt-5'>
                        {
                            getFromThisService_Ponints?.map((ele, i) => <span key={i} className='flex gap-x-2 items-start'>
                                <img src={doubleTick} alt="double-tics" />
                                <p>{ele}</p>
                            </span>
                            )
                        }
                    </div>
                </div>
            </div>

        </section>
    )
}

export default ContentPage;