import React from 'react'
import { AiFillStar } from 'react-icons/ai'

import DoubleQuote from '../../../Assets/Testimonial/Vector (2).svg'
import TreeImg from '../../../Assets/ContactUs/treeBranch.svg';
import RedCircleImg from '../../../Assets/ContactUs/roundStripCollection.svg'

import { cleientFeedbackArray } from '../../../clientFeedback';




const TestimonialCard = () => {
    return (
        <div className='max-w-[1600px] m-auto relative'>
            <div className='relative lg:w-10/12 w-11/12 m-auto  text-center flex flex-col gap-6 pt-12 lg:pb-40 pb-12'>
                {/* <h1 className="lg:text-4xl md:text-3xl text-2xl font-['Cormorant_Garamond'] font-bold ">Our Testimonials</h1>
                <h1 className='lg:text-5xl md:text-4xl text-3xl font-bold text-[#B40101] mt-6 mb-4'>Our Client's Feedback</h1> */}
                <div className='w-11/12 m-auto grid md:grid-cols-2 grid-cols-1 md:mt-24  gap-10'>
                    {
                        cleientFeedbackArray?.map((ele, i) => (
                            <Card key={i} {...ele} />
                        ))
                    }
                </div>
            </div>
            <img src={TreeImg} alt={TreeImg} className='hidden lg:block absolute w-96 -top-28 right-0' />
            <img src={RedCircleImg} alt={RedCircleImg} className='hidden lg:block absolute w-40 -bottom-6 left-0' />
        </div>
    )
}

export default TestimonialCard
const Card = ({ img, feedback, name, designation }) => {

    return (
        <div className=' flex lg:gap-6 md:gap-4 gap-3 justify-between items-start flex-col md:flex-row text-left bg-[#E9A8B24D] rounded-xl lg:p-6 p-5 '>
            <div className=' '>
                <img src={img} alt={name} className='lg:w-20 lg:h-20 w-16 h-16 rounded-full border' />
            </div>
            <div className='lg:w-[77%] md:w-[70%]'>
                <div className='flex gap-1'>
                    <AiFillStar className=' text-yellow-700' />
                    <AiFillStar className=' text-yellow-700' />
                    <AiFillStar className=' text-yellow-700' />
                    <AiFillStar className=' text-yellow-700' />

                </div>
                <p className='text-xs text-[#323232] mt-4'>
                    {feedback}
                </p>
                <div className=' flex justify-between items-center'>
                    <div>
                        <p className=' mt-3 font-semibold text-[#B40101]'>
                            {name}
                        </p>
                        <p className='text-sm text-[#323232]'>
                            {designation}
                        </p>
                    </div>
                    <div>
                        <img src={DoubleQuote} alt="" className='w-10' />
                    </div>
                </div>
            </div>
        </div>
    )
}