import React from 'react'

import preWed from '../../../Assets/LandingPage/preWedShoot1.webp'
import preWed2 from '../../../Assets/LandingPage/preWedShoot2.webp'
import leaf from '../../../Assets/LandingPage/leaf.svg'
import Form from './Form/Form'

const ServiceForm = () => {
    return (
        <section className='max-w-[1600px] mx-auto'>
            <div className='w-11/12 mx-auto lg:w-10/12'>
                <div className='grid lg:grid-cols-2 grid-cols-1 mt-16 mb-24'>
                    <div className='flex items-center relative'>
                        <div className='self-end flex flex-col relative'>
                            <img src={leaf} alt="leaf" className='w-24 absolute -top-1/4 left-1/3 translate-y-0 -translate-x-1/ hidden md:block ' />
                            <img src={preWed2} alt="pre wedding shoot" className='rounded-lg translate-y-10 md:translate-x-14  translate-x-10' />
                        </div>
                        <div>
                            <img src={preWed} alt="pre wedding shoot large" className='rounded-lg' />
                        </div>
                    </div>
                    <div className="w-full lg:w-11/12 mx-auto mt-20  lg:mt-0">
                        <iframe
                            title="contact-form"
                            className='h-[744px] lg:h-[730px] w-full overflow-hidden'
                            aria-label='Bliss Events'
                            frameborder="0"
                            src='https://forms.zohopublic.in/gbjbuzz/form/BlissEvents/formperma/dbo_1swf1z5XqlgkXlymOgMf3XosPtpr2WIvqmNmUU4'></iframe>

                    </div>
                </div>

            </div>
        </section>
    )
}

export default ServiceForm