import React, { useEffect } from 'react'
import { Typography } from '@material-tailwind/react'

import HeaderSection from '../Components/HeaderSection/HeaderSection'
import Member from '../Components/LandingPage/Team/Member/Member'
import Member3 from '../Assets/People/Team/praveen-bhagavatula.svg'
import Member1 from '../Assets/People/Team/vijay-barapatre.png'
import Member2 from '../Assets/People/Team/gunjan-jagnade .svg'
import Member4 from '../Assets/People/Team/shiv-prasad.svg'
import Member5 from '../Assets/People/Team/atharva-dhakulkar .svg'
import Member6 from '../Assets/People/Team/chinu-makkad.svg'
import Member11 from '../Assets/People/Team/AD1.png'
import Member12 from '../Assets/People/Team/PB.png'


const memberArray = [
    {
        img: Member1,
        name: "Vijay Barapatre",
        designation: "CEO and MD",
    },
    {
        img: Member11,
        name: "Atharva Dhakulkar",
        designation: "Head Management",
    },
    {
        img: Member12,
        name: "Praveen Bhagavatula",
        designation: "COO",
    },
    {
        img: Member4,
        name: "Shiv Prasad",
        designation: "Chief Sales Officer",
    },
    {
        img: Member6,
        name: "Chinu Makkad",
        designation: "Creative Head",
    },
]

const TeamPage = () => {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <div>
            <HeaderSection title={"Our Team"} />
            <div className='w-11/12 mx-auto lg:w-10/12 my-16 max-w-[1600px]'>
                <div className='my-10'>
                    <Typography variant='h3' className="font-['Cormorant_Garamond'] text-center text-[#323232] text-2xl lg:text-5xl">
                        Organizers
                    </Typography>
                    <Typography variant='h3' className="text-[#B40101] font-bold text-center  mx-auto text-2xl lg:text-5xl">
                        Meet Our Lovely Team Member
                    </Typography>
                </div>

                <div className='md:grid grid-cols-3 md:justify-between items-stretch justify-center flex flex-wrap gap-y-5'>
                    {
                        memberArray?.map((ele, i) => <Member key={i} {...ele} />)
                    }
                </div>

            </div>
        </div>
    )
}

export default TeamPage