import React, { useEffect } from 'react'
import Questions from '../Components/FaqPage/Questions';
import HeaderSection from '../Components/HeaderSection/HeaderSection';
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GA_ID);

const FaqPage = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Faq Page" });

    window.scroll(0, 0)
  }, [])
  return (
    <div>
      <HeaderSection title='Frequently Asked Questions' />
      <Questions />

    </div>
  )
}

export default FaqPage;
