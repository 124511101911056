
import React from 'react'

const TestimonialCard = ({ img, name, designation, feedback }) => {
    return (
        <div className='flex justify-between items-start text-left bg-[#E9A8B24D] rounded-lg p-4 py-8'>
            <div className=' '>
                <img src={img} alt={name} className='rounded-full p-2' />
            </div>
            <div className='w-11/12'>
                <p className='text-xs text-[#323232] h-32 lg:h-[115px] xl:h-20'>{feedback}</p>
                <p className='text-sm mt-3 font-bold text-[#B40101]'>{name}</p>
                <p className='text-sm text-[#323232]'>{designation}</p>
            </div>
        </div>
    )
}

export default TestimonialCard