import React, { useEffect } from 'react'
import HeaderSection from '../Components/HeaderSection/HeaderSection'
import WeddingAboutServices from '../Components/Wedding/WeddingAboutServices';
import HeroSwiper from '../Components/Wedding/HeroSwiper'
import Categories from '../Components/LandingPage/Categories/Categories'

import banglorePalace from '../Assets/RoyalWedding/banglore-palace.svg'
import tajPalace from '../Assets/RoyalWedding/taj-lake-palace.svg'
import laxmiPalace from '../Assets/RoyalWedding//laxmi-vilas-palace.svg'
import falaknumaPalace from '../Assets/RoyalWedding/falaknuma-palace.svg'
import jaiPalace from '../Assets/RoyalWedding/jai-vilas-palace.svg'
import SmallSwiper from '../Components/Wedding/SmallSwiper'
import img1 from '../Assets/RoyalWedding/royal-card1.svg';
import img2 from '../Assets/RoyalWedding/royal-card2.svg';
import img3 from '../Assets/RoyalWedding/royal-card3.svg';
import img4 from '../Assets/RoyalWedding/royal-card4.svg';
import img5 from '../Assets/RoyalWedding/royal-card5.svg';
import img6 from '../Assets/RoyalWedding/royal-card6.svg';
import OurWeddingStories from '../Components/Wedding/OurWeddingStories';
import Image1 from '../Assets/RoyalWedding/royal-weeding1.svg'
import Image2 from '../Assets/RoyalWedding/royal-weeding2.svg'
import Image3 from '../Assets/RoyalWedding/royal-weeding3.svg'
import Image4 from '../Assets/RoyalWedding/royal-weeding4.svg'

import img11 from '../Assets/RoyalWedding/royal-stories1.svg';
import img22 from '../Assets/RoyalWedding/royal-stories2.svg'
import img33 from '../Assets/RoyalWedding/royal-stories3.svg'
import img44 from '../Assets/RoyalWedding/royal-stories4.svg'
import img55 from '../Assets/RoyalWedding/royal-stories5.svg';
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GA_ID);

const swiperArray = [
    {
        img: banglorePalace,
        alt: "banglorePalace",
    },
    {
        img: tajPalace,
        alt: "tajPalace",
    },
    {
        img: laxmiPalace,
        alt: "laxmiPalace",
    },
    {
        img: falaknumaPalace,
        alt: "falaknumaPalace",
    },
    {
        img: jaiPalace,
        alt: "jaiPalace",
    },
]


const page = {
    title1: "Top Best Places for your Dream Wedding",
    detailsArray1: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
    ],
    title2: "Complete Guide for destination wedding",
    detailsArray2: [
        "Voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro  quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, ",
    ],
    // list: ["Kerla", "Goa", "Jodhpur", "Jaipur", "Udaipur",],
    smallSwiperArray: [
        {
            img: Image1,
            alt: "destinationWedding1",
        },
        {
            img: Image2,
            alt: "destinationWedding2",
        },
        {
            img: Image3,
            alt: "destinationWedding3",
        },
        {
            img: Image4,
            alt: "destinationWedding4",
        },

    ],
    servicesList: [
        { img: img1 }, { img: img2 }, { img: img3 }, { img: img4 }, { img: img5 }, { img: img6 },
    ],
    about_p: "Voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatiset quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur autodit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porroquisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.",
    getFromThisService: "We orchestrate moments of joy and merriment, infusing the atmosphere with lively music, traditional performances, and engaging activities that bring everyone closer together in celebration.",
    getFromThisService_Ponints: [
        "Timing and Coordination: Planning the schedule for the Haldi ceremony, including timing for the arrival of family and friends, ritual performances, and any cultural customs associated with the ceremony.",
        "Guest Comfort and Hospitality: Providing assistance to guests by ensuring their comfort, offering refreshments, and overseeing any special requirements or accommodations.",
        "Entertainment and Music: Organizing traditional music or performances to add cultural vibrancy and joy to the ceremony.",
        "Assistance for the Couple: Offering support and assistance to the bride and groom, ensuring they are comfortable and have everything they need for the ceremony.",
        "Clean-Up and Post-Event Management: Managing the clean-up after the ceremony, ensuring that the venue is left in its original state and handling any necessary follow-ups or arrangements.",
    ],
    weddingServicesCard: [
        {
            name: "Mahesh & Madhavi",
            city: "Bangalore Palace",
            img: img11
        },
        {
            name: "Jack & Rose",
            city: "Taj Lake Palace",
            img: img22
        }, {
            name: "Rakesh & Mamya",
            city: "Laxi Vilas Palace",
            img: img33
        }, {
            name: "Nick & Hazel",
            city: "Falaknuma Palace",
            img: img44
        }, {
            name: "Ash & Ember",
            city: "Jai Vilas Palace",
            img: img55
        }
    ]
}



const RoyalWedding = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Royal wedding Page" });
        window.scroll(0, 0)
    }, [])

    return (
        <div>
            <HeaderSection title={"Royal Wedding"} />
            <HeroSwiper swiperArray={swiperArray} title1={page.title1} detailsArray1={page.detailsArray1} />
            <SmallSwiper {...page} />
            <WeddingAboutServices {...page} />
            <OurWeddingStories {...page} flight={false} />
            <Categories />
        </div>
    )
}

export default RoyalWedding;
