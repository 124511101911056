import React from 'react'
import { Typography } from '@material-tailwind/react'
import { Link } from 'react-router-dom'
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

import weddingimg from '../../../Assets/LandingPage/WeddingPhoto.webp'
import weddingimg2 from '../../../Assets/LandingPage/WeddingPhoto2.svg'
import treeBranch from '../../../Assets/LandingPage/Tree&flower/about_tree_branch.svg'
import Ring from '../../../Assets/LandingPage/EngagementRing.svg'
// import Sidetree from '../../../Assets/LandingPage/SideTree.svg';

import familiesImg from '../../../Assets/LandingPage/AboutCard/heart.svg';
import yearsImg from '../../../Assets/LandingPage/AboutCard/mdi_heart.svg';
import projectImg from '../../../Assets/LandingPage/AboutCard/Mask group.svg';


const AboutBliss = () => {
    const [trigger, setTrigger] = React.useState(false)

    return (
        <section className=' relative max-w-[1600px] mx-auto '>
            <div className='lg:w-10/12 mx-auto w-11/12 md:mt-7 mt-4 '>

                <div className='flex justify-between items-center md:mt-28 mt-20 mb-12 flex-col lg:flex-row'>
                    <div className='lg:w-1/2 flex justify-normal items-end sm:w-full'>
                        <div className='flex flex-col relative'>
                            <img src={treeBranch} alt="tree Branch" className='w-28 self-end ' />
                            <img
                                data-aos="fade-right"
                                data-aos-duration="1000"
                                data-aos-delay="250"
                                src={weddingimg2} alt="wedding pic " className='relative left-2 md:left-4 -top-10 rounded-xl z-10' loading='lazy' />
                            <div
                                data-aos="fade-right"
                                data-aos-duration="1000"
                                data-aos-delay="150"
                                className='border bg-white rounded-lg border-[#b40101] w-max lg:pl-2 lg:pr-4 lg:py-1 px-2 py-[2px]  font-semibold text-[#b40101] flex justify-around items-center absolute self-end  bottom-10 left-2/3 z-20 shadow-xl '>
                                <img src={Ring} alt="enagaement Ring" className='h-10 lg:h-auto' />
                                <Typography

                                    variant='paragraph' className='font-semibold uppercase text-center text-xs lg:text-base ' >
                                    10+ <br />Experiences
                                </Typography>
                            </div>
                        </div>
                        <div>
                            <img
                                data-aos="fade-right"
                                data-aos-duration="1000"
                                src={weddingimg} alt="wedding pic" className='rounded-xl' loading='lazy' />
                        </div>
                    </div>
                    <div className='self-center lg:w-[45%]  w-full flex flex-col gap-y-8 mt-5 lg:mt-0'>
                        <Typography variant='h2' className='text-[#B40101] font-bold uppercase text-2xl 2xl:text-5xl md:text-4xl' >
                            aBOUT bLISS eVENT
                        </Typography>
                        {/* <Typography variant='h5' className='font-normal  text-lg md:text-2xl  leading-[3.5rem] md:leading-8' >
                                We Inspire You To Bring We Inspire You To Bring Your Wedding In Real Life Your Wedding In Real Life
                            </Typography> */}
                        <Typography variant='paragraph' className=' text-[#323232]  md:leading-6 text-[15px] font-medium ' >
                            The Bliss Events embodies the art of transforming dreams into reality. With a dedicated team driven by innovation and an eye for detail, we transform visions into reality. Our journey is defined by a commitment to excellence, crafting bespoke experiences that capture the essence of every celebration.
                        </Typography>
                        <Typography variant='paragraph' className=' text-[#323232]  md:leading-6 text-[15px] font-medium ' >
                            From elegant weddings to mesmerizing events, we infuse creativity and expertise into every facet, ensuring each occasion is a masterpiece. At The Bliss Events, we embrace diversity, infuse elegance, and curate moments that linger in the hearts of our clients and their guests long after the event concludes.
                        </Typography>

                        <ScrollTrigger
                            onEnter={() => setTrigger(true)}
                        >
                            <div className='flex justify-between gap-x-2 md:justify-around lg:justify-between  items-start flex-wrap lg:flex-nowrap md:gap-y-2 gap-y-3'>
                                <div className=' flex items-center gap-x-3 '>
                                    <div className='w-10 h-10 flex justify-center items-center ' style={{ boxShadow: "0px 0px 10px 1px rgba(165,165,165,0.6)" }}>
                                        <img src={familiesImg} alt="families-image" className='w-5' />

                                    </div>
                                    <div className='flex justify-center flex-col items-start lg:w-max'>
                                        <p className=' font-semibold md:text-xl '>
                                            {
                                                trigger &&
                                                < CountUp start={0} end={150} duration={5} delay={0} />
                                            }+

                                        </p>
                                        <p className='xl:text-xs lg:text-[10px] text-[9px] font-semibold text-left md:w-full'>
                                            Families Happily Served
                                        </p>
                                    </div>
                                </div>
                                <div className=' flex items-center gap-x-3'>
                                    <div className='w-10 h-10 flex justify-center items-center ' style={{ boxShadow: "0px 0px 10px 1px rgba(165,165,165,0.6)" }}>
                                        <img src={yearsImg} alt="families-image" className='w-6' />

                                    </div>
                                    <div className='flex justify-center flex-col items-start lg:w-max'>
                                        <p className=' font-semibold md:text-xl '>
                                            {
                                                trigger &&
                                                < CountUp start={0} end={10} duration={5} delay={0} />
                                            }+

                                        </p>
                                        <p className='xl:text-xs lg:text-[10px] text-[9px] font-semibold text-left md:w-full'>
                                            Years of Experienced
                                        </p>
                                    </div>
                                </div>
                                <div className=' flex items-center gap-x-3'>
                                    <div className='w-10 h-10 flex justify-center items-center ' style={{ boxShadow: "0px 0px 10px 1px rgba(165,165,165,0.6)" }}>
                                        <img src={projectImg} alt="families-image" className='w-6' />

                                    </div>
                                    <div className='flex justify-center flex-col items-start lg:w-max'>
                                        <p className=' font-semibold md:text-xl '>
                                            {
                                                trigger &&
                                                < CountUp start={0} end={12} duration={5} delay={0} />
                                            }K
                                        </p>
                                        <p className='xl:text-xs lg:text-[10px] text-[9px] font-semibold text-leftmd:w-full'>
                                            Going projects
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </ScrollTrigger>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default AboutBliss