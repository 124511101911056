import React, { useEffect, useState } from "react";
import { VscChevronRight } from "react-icons/vsc";
import { GoDotFill } from "react-icons/go";
import { Link, useLocation } from "react-router-dom";

const servicesListData = [
    {
        title: "Service Details",
        link: "/services",
    },
    {
        title: "Wedding Reception",
        link: "/service/reception",
    },

    {
        title: "Photography Event",
        link: "/service/photography",
    },
    {
        title: "Haldi Event",
        link: "/service/haldi",
    },
    {
        title: "Modeling Decoration",
        link: "/service/decoration",
    },
    {
        title: "Bride Make up",
        link: "/service/bridal-makeup",
    },
    {
        title: "Caterers",
        link: "/service/caterers",
    },
];

const topServisesList = [
    "Decoration Plan",
    "Photography Event",
    "Perfect Wedding Plan",
    "Bridal Dress",
    "Hair Style",
    "Enterainments",
    "Catering services",
    "Top Services",
];

const Categories = () => {
    const [newArray, setNewArray] = useState([]);
    const pathname = useLocation();

    useEffect(() => {
        const filteredAray = servicesListData.filter(
            (ele) => ele.link !== pathname.pathname
        );
        setNewArray(filteredAray);
    }, []);
    return (
        <div className=" lg:w-[30%] md:w-10/12 w-11/12 mx-auto ">
            <div className=" max-w-[1600px]  lg:w-10/12 w-11/12 m-auto my-12 ">
                <div className=" flex flex-col gap-y-[10px]">
                    {newArray?.map((el, i) => (
                        <Link to={el.link} key={i}>
                            <div className=" w-80 flex justify-between items-center rounded-[4px] bg-white py-[14px] px-6 text-[#051145] group  hover:bg-[#B40101] !transition-all !duration-700 !ease-linear shadow-[0px_6px_20px_rgba(5,17,69,0.08)] ">
                                <h5 className="text-sm group-hover:text-white !transition-all !duration-500 !ease-linear">
                                    {el.title}
                                </h5>
                                <VscChevronRight
                                    size={20}
                                    className="-mr-1 group-hover:text-white !transition-all !duration-500 !ease-linear"
                                />
                            </div>
                        </Link>
                    ))}
                </div>
                <div className="flex flex-col gap-y-4 w-80 px-6 py-6 mt-20 rounded-[4px] shadow-[0px_6px_20px_rgba(5,17,69,0.08)]">
                    <h4 className="font-semibold">Our Brochure</h4>
                    <div className="flex items-center">
                        <div className="w-6 h-[3px] bg-[#B40101]"></div>
                        <div className="w-full h-[1px] bg-[#B40101]"></div>
                    </div>
                    <p className=" text-sm">
                        Explore the essence of our company through our brochure. Dive into a
                        comprehensive showcase of our values & offerings.
                    </p>
                    <a
                        href="https://drive.google.com/file/d/1WfTq-HWSTE9O9p1zcJnh7u5JC0_-H7Er/view?usp=sharing"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-48 text-sm text-white bg-[#B40101] py-3 px-2 text-center "
                    >
                        Download PDF Now
                    </a>
                </div>
                <div className="flex flex-col gap-y-4 w-80 px-6 py-6 mt-20 rounded-[4px] shadow-[0px_6px_20px_rgba(5,17,69,0.08)]">
                    <h4 className="font-semibold">Top Services</h4>
                    <div className="flex items-center">
                        <div className="w-6 h-[3px] bg-[#B40101]"></div>
                        <div className="w-full h-[1px] bg-[#B40101]"></div>
                    </div>
                    <div className="flex flex-col gap-y-2">
                        {topServisesList.map((el, i) => (
                            <div key={i} className=" flex gap-x-3 items-center">
                                <GoDotFill className="text-[#B40101] text-sm" />
                                <p className="text-sm">{el}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Categories;
