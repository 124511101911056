import React, { useRef } from 'react'
import {
    Card,
    CardBody,
    Typography,
} from "@material-tailwind/react"




const ItemCard = ({ img, title, desc ,i}) => {
    return (
        // <Card className="group mt-6 h-52 max-w-[18rem] border-[#B40101] border shadow-md shadow-[#B40101]/30 overflow-hidden" >
        //     <CardBody className='!py-0 flex justify-center items-center flex-col gap-y-5'>
        //         <div className='flex justify-center items-center flex-col gap-y-5 translate-y-[40%] transition-all duration-200 ease-linear group-hover:-translate-y-[58%] xl:group-hover:-translate-y-[55%] lg:group-hover:-translate-y-[50%] md:group-hover:-translate-y-[54%]' >
        //             <div

        //                 className='p-2 w-max rounded-full bg-[#E4D7D9] '>
        //                 <img src={img} alt={title} loading='lazy' className='w-10' />
        //             </div>
        //             <Typography variant="paragraph" className=" text-[#B40101] font-semibold uppercase lg:text-sm  xl:text-base lg:h-14 xl:h-9 ">
        //                 {title}
        //             </Typography>
        //         </div>
        //         <div className=' translate-y-72 transition-all duration-200 ease-linear group-hover:-translate-y-[100%]  xl:group-hover:-translate-y-[85%] lg:group-hover:-translate-y-[100%] md:group-hover:-translate-y-[82%]'>
        //             <Typography className='text-[#323232] lg:text-sm'>
        //                 {desc}
        //             </Typography>
        //         </div>
        //     </CardBody>
        // </Card>
        <Card className={`group mt-6 md:h-[13.2rem] h-[10rem] max-w-[18rem]   border-[#B40101] border shadow-md shadow-[#B40101]/30 overflow-hidden !transition-all !duration-700 !ease-linear bg-[#f7e1e4] hover:bg-[#B40101] `} >
        <CardBody className={`flex !justify-center !items-center flex-col !p-4  
        text-[#B40101] group-hover:!text-white md:!h-auto !h-full `}>
            <div className='md:group-hover:flex group-hover:hidden flex justify-center items-center flex-col gap-y-5 ' >
                <div

                    className='p-3 w-max rounded-full bg-[#ECF0F2] '>
                    <img src={img} alt={title} loading='lazy' className='w-8 ' />
                </div>
                <Typography variant="paragraph" className="  font-semibold uppercase lg:text-sm  xl:text-base text-sm text-center !transition-all !duration-700 !ease-linear ">
                    {title}
                </Typography>
            </div>
            <div className='md:static absolute group-hover:static bottom-60  md:translate-y-0 translate-y-[70%] group-hover:translate-y-[10%] !transition-all !duration-500 !ease-linear '>
                <Typography className={`lg:text-sm !leading-[18px] text-center !transition-all !duration-700 !ease-linear text-[#323232] group-hover:!text-white`}>
                    {desc}
                </Typography>
            </div>
        </CardBody>
    </Card>
    )
}

export default ItemCard