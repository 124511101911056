import React, { useEffect, useState } from 'react'
import BlogCard from './BlogsCard/BlogCard'
import CustomCarousel from '../../CutomCarousel/CustomCarousel';
import imageUrlBuilder from '@sanity/image-url';
import { client } from '../../../sanity';


const Blogs = () => {
    const [blogs, setBlogs] = useState([])
    const builder = imageUrlBuilder(client)
    // <img src={`${builder.image(blogData[0]?.blogimage).url()}`} alt='blog-image' className='mt-8' />
    const fetchData = async () => {
        const query = `*[_type == "blog"][0..2]`;
        const blogs = await client.fetch(query);
        setBlogs(blogs)
    }

    useEffect(() => {
        fetchData()
    }, [])

    // console.log("BBBBBBBBBBB", blogs)

    return (
        <section className='bg-[#E9A8B240]'>
            <div className='max-w-[1600px] mx-auto'>
                <div className='w-11/12 mx-auto lg:w-10/12 md:py-10 pt-10 pb-6'>
                    <div className='text-center flex flex-col items-center gap-y-5'>
                        <h2 className="font-['Cormorant_Garamond'] font-bold lg:text-3xl text-xl">
                            Blogs
                        </h2>
                        <h5 className='text-[#B40101] lg:text-4xl font-bold text-2xl'>
                            Our Creation
                        </h5>
                    </div>
                    <div className='hidden md:grid md:grid-cols-3  justify-between items-stretch gap-x-2 gap-y-5 my-10'>
                        {
                            blogs.length > 0 &&
                            blogs?.map((ele, i) => <BlogCard key={i} {...ele} id={i} />)
                        }
                    </div>
                    <div className='md:hidden mt-6'>
                        {
                            blogs.length > 0 &&
                            <CustomCarousel swiperArray={blogs} cardType={"blog"} />
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Blogs